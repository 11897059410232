<template>
  <v-layout row wrap v-if="isAuthenticated">
    <template v-if="error">
      <h2>{{ error }}</h2>
    </template>
    <v-overlay :value="overlay">
      <div class="text-center">
      <v-progress-circular indeterminate size="128"></v-progress-circular>
      <h1>Please wait...</h1>
      </div>
    </v-overlay>
    <v-flex xs12 sm12 lg12>
      <v-card>
        <v-card-title>
            <div v-if="activatorOnly" class="text-h5 primary--text">Activation Log</div>
            <div v-else class="text-h5 primary--text">Hunter Log</div>
          <v-spacer></v-spacer>
          <filter-drop-menu name="P2P Only" v-model="p2pOnly" :options="p2pOptions" nocount></filter-drop-menu>
          <v-spacer></v-spacer>
          <v-menu
              v-model="menuDateRange"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="disableFilterControls"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Date Range"
                  readonly
                  outlined
                  hide-details
                  :prepend-inner-icon="$vuetify.icons.values.mdiCalendar"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disableFilterControls"
                >
                </v-text-field>
              </template>
              <v-date-picker range scrollable v-model="dateRange">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateRange = []; menuDateRange = false; update()">Cancel / Clear</v-btn>
                <v-btn text color="primary" @click="menuDateRange = false; update()">OK</v-btn>
              </v-date-picker>
            </v-menu>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            outlined
            hide-details
            @keyup.enter="doSearch()"
            class="horizontal-list-container"
            :disabled="disableFilterControls"
          >
            <template v-slot:append>
              <v-icon @click="doSearch()">{{$vuetify.icons.values.mdiMagnify}}</v-icon>
            </template>
          </v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table dense disable-sort disable-filtering
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100]
            }"
            :items-per-page="25"
            :loading="loading"
            :options.sync="options"
            :headers="logbookHeaders"
            :items="logbook"
            :server-items-length="count">
            <template v-slot:[`item.tags`]="{ item }">
              <v-chip label x-small color="success" v-if="item.activator">Activator</v-chip>
              <v-chip label x-small color="orange" v-else>Hunter</v-chip>
              <v-chip label x-small color="accent" v-if="item.p2pMatch">P2P</v-chip>
            </template>
            <template v-slot:[`item.qsoDateTime`]="{ item }">
              {{ item.qsoDateTime | dateTime }}
            </template>
            <template v-slot:[`item.station_callsign`]="{ item }">
              <user-stats :callsign="item.station_callsign"></user-stats>
            </template>
            <template v-slot:[`item.operator_callsign`]="{ item }">
              <user-stats :callsign="item.operator_callsign"></user-stats>
            </template>
            <template v-slot:[`item.worked_callsign`]="{ item }">
              <user-stats :callsign="item.worked_callsign"></user-stats>
            </template>
            <template v-slot:[`item.mode`]="{ item }">
              {{ item.mode }} ({{ item.loggedMode }})
            </template>
            <template v-slot:[`item.reference`]="{ item }">
              <router-link :to="{ name: 'park', params: { id: item.reference } }">{{ item.reference }} {{ item.name }} {{ item.parktypeDesc }}</router-link>
            </template>
          </v-data-table>
          <small>
            <p>Your QSOs as a Hunter can be found on your <a @click="activationReference=null; activationDate=null; hunterReference=null; search=null; dateRange=[]; update();">My Hunter Log</a> page. For QSOs as an Activator, go to your <router-link to="/user/activations">My Activations</router-link> page, find your activation, and click <kbd>View</kbd>.</p>
          </small>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<style scoped>
.horizontal-list {
  list-style-type: none;
  overflow: hidden;
}
.horizontal-list li.heading {
  font-weight: bold;
}
.horizontal-list li {
  float: left;
  margin-right: 0;
}
.horizontal-list-container {
  margin: 12px 0 12px -12px;
}
</style>

<script>
import FilterDropMenu from '@/components/FilterDropMenu'
import UserStats from '@/components/UserStats'

import axios from 'axios'

export default {
  beforeMount() {
  },
  mounted () {
    this.initialize()
  },
  beforeCreate() {
  },
  created() {
    this.initialize()
  },
  beforeUpdate() {
  },
  updated() {
  },
  methods: {
    initialize() {
      if (this.activationReference)
        this.search = this.activationReference

      if (this.activationDate)
        this.dateRange = [this.activationDate]

      if (this.hunterReference)
        this.search = this.hunterReference
    },
    doSearch() {
      this.options.page = 1
      this.update()
    },
    update() {
      this.loading = true

      const { page, itemsPerPage } = this.options

      this.error = null
      this.logbook = []

      var params = '?'

      if (this.activatorOnly)
        params += "activatorOnly=1"
      else
        params += "hunterOnly=1"

      if (this.userId != null && this.userId != this.$store.state.user.userId && this.isUserSupport)
        params += `&userId=${this.userId}`

      if (page)
        params += `&page=${page}`

      if (itemsPerPage)
        params += `&size=${itemsPerPage}`

      if (this.p2pOnly == 'Yes')
        params += "&p2pOnly=1"

      if (this.dateRange != null && this.dateRange.length > 0)
      {
        var dates = this.dateRange.sort(); // ensure earliest date is first; picker doesn't care
        if (dates.length == 1)
          params += `&startDate=${dates[0]}&endDate=${dates[0]}`
        else
          params += `&startDate=${dates[0]}&endDate=${dates[1]}`
      }

      if (this.activatorOnly && this.activationReference)
        params += `&reference=${encodeURIComponent(this.activationReference)}`
      else if (this.search && this.search != '')
        params += `&search=${encodeURIComponent(this.search)}`

      axios
        .get(`https://${process.env.VUE_APP_API_URL}/user/logbook${params}`, this.$store.getters.authTokenHeader)
        .then(response => response.data)
        .then(json => {
          this.count = json.count;

          var id = this.userId
          if (id == null)
            id = this.$store.state.user.userId
          for (var i = 0; i < json.entries.length; i++) {
            json.entries[i].activator = this.activatorOnly || (json.entries[i].userId == id)
          }

          this.logbook = json.entries;
          this.loading = false
        })
        .catch(err => {
          this.error = err.repose.data
        })
    },
  },
  data () {
    return {
      needLoad: true,
      error: null,
      loading: false,
      overlay: false,
      options: {},
      search: '',
      dateRange: [],
      menuDateRange: false,
      count: 0,
      logbook: [],
      logbookHeaders: [
          { value: 'tags', text: '', align: 'left' },
          { value: 'qsoDateTime', text: 'Date/Time', align: 'left' },
          { value: 'station_callsign', text: 'Station', align: 'left' },
          { value: 'operator_callsign', text: 'Operator', align: 'left' },
          { value: 'worked_callsign', text: 'Worked', align: 'left' },
          { value: 'band', text: 'Band', align: 'center' },
          { value: 'mode', text: 'Mode', align: 'center' },
          { value: 'locationDesc', text: 'Location', align: 'center' },
          { value: 'reference', text: 'Park', align: 'left' },
          //{ value: 'name', text: 'Park Name', align: 'left' },
        ],
      logSelect: 'Hunter',
      logSelectOptions: [
        { value: 'Hunter' },
        { value: 'Activator' },
        //{ value: 'Both (Slow)' }
      ],
      p2pOnly: 'No',
      p2pOptions: [
        { value: 'No' },
        { value: 'Yes' },
      ]
    }
  },
  watch: {
      options: {
        handler () {
          this.update()
        },
        deep: true,
      },
      logSelect: {
        handler () {
          this.update()
        },
      },
      p2pOnly: {
        handler () {
          this.update()
        },
      },
    },
  props: [ 'userId', 'activationReference', 'activationDate', 'hunterReference' ],
  computed: {
    isAuthenticated: {
      get () {
        return this.$store.state.user.isAuthenticated
      }
    },
    isUserSupport: {
      get () {
        return this.$store.state.user.isUserSupport
      }
    },
    dateRangeText () {
      if (this.dateRange == null || this.dateRange == '')
        return null;
      return this.dateRange.join(' ~ ')
    },
    disableFilterControls: {
      get() {
        if (this.activationReference || this.activationDate || this.hunterReference)
          return true
        return false
      }
    },
    activatorOnly: {
      get() {
        if (this.activationReference || this.activationDate)
          return true
        return false
      }
    }
  },
  components: {
    FilterDropMenu,
    UserStats
  }
}
</script>
