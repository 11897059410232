<template>
  <v-layout row wrap v-if="isAuthenticated">
    <template v-if="error">
      <h2>{{ error }}</h2>
    </template>
    <v-overlay :value="overlay">
      <div class="text-center">
      <v-progress-circular indeterminate size="128"></v-progress-circular>
      <h1>Please wait...</h1>
      </div>
    </v-overlay>
    <v-flex xs12 sm12 lg12>
      <!-- -------------------------- -->
      <template v-if="(!userId && isLogUploader) || isLogManager">
      <v-alert colored-border border="left" elevation="2" color="warning" v-if="$vuetify.breakpoint.smAndDown">
        Unfortunately, uploading logs is not yet supported on this screen size. 
        To submit logs, please use a higher display resolution. (Hint: Try turning your phone sideways, it just may work.)
      </v-alert>

      <v-alert colored-border border="left" elevation="2" color="success" v-if="$vuetify.breakpoint.mdAndUp">
        <v-card flat>
          <v-card-title>
            Upload Activation Logs
            <v-spacer/>
            <support-dialog
                topic="Log Uploading"
                :tags="['log']"
                notes="A support ticket will be created. It may take a few days for the support team to get back to you. If you are having an issue with a particular log, reply to the ticket email with an attached copy of the log.">
              <template #activator="{ on, attrs }">
                <v-btn color="primary" outlined small v-bind="attrs" v-on="on"><v-icon left>{{$vuetify.icons.values.mdiLifebuoy}}</v-icon> Request Support</v-btn>
              </template>
            </support-dialog>
          </v-card-title>
          <v-card-text>
            <log-upload :userId="userId"></log-upload>
            <div v-if="!userId">
              Prefer to log on paper?
              <v-btn color="primary" outlined tile small :to="{ name: 'user_mle' }">
                <v-icon left>{{$vuetify.icons.values.mdiNotebookEditOutline}}</v-icon> Manual Log Entry<sup>beta</sup>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-alert>
      </template>
      <template v-else>
        <v-alert dense outlined border="left" type="info">
          <b>The POTA uploader is now available to all users!</b> To gain access, sign out and then sign back in. If that doesn't work, 
          give it an hour and try again. If you are still seeing this message after that, <support-dialog
                topic="User Accounts"
                :tags="['account']"
                publicMessage="I am having trouble accessing the new uploader! I have tried signing out and signing back in and I've also waited an hour and tried again. Please help me!"
                notes="A support ticket will be created. It may take a few days for the support team to get back to you.">
              <template #activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on"><b>CLICK HERE</b></a>
              </template>
            </support-dialog> to contact our support team and they will help you get access.
        </v-alert>
      </template>


      <v-alert dense outlined border="left" type="warning">
        <p>Once your log is processed, it may take up to 24 hours for your logbook and stats to fully reflect your uploaded QSOs.
        Most awards are granted within a few days, but some awards may take a few weeks.</p>
        <strong>Thank you for being patient!</strong>
      </v-alert>

      <v-alert dense outlined border="left" type="success" v-if="needReload">
        <p>Logs are usually processed within minutes of being received, but <strong>sometimes it may take longer.</strong> If your log upload shows 
        <strong>Pending</strong> then it is safe in the queue and will be processed as soon as the system is able to do so. There is no need to 
        contact support unless you log has been Pending for <strong>more than 24 hours.</strong></p>
        This page will automatically reload every 15 seconds while there are pending log uploads.
      </v-alert>

      <!-- -------------------------- -->
      <v-card outlined rounded class="quiet-links">
        <v-card-title>
          Uploaded Logs
          <v-spacer/>
          <v-switch v-model="showAll" label="Show All" color="primary"></v-switch>
          &emsp;
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            outlined
            hide-details>
            <template v-slot:append>
              <v-icon @click="doSearch()">{{$vuetify.icons.values.mdiMagnify}}</v-icon>
            </template>
          </v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table dense show-expand
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100]
            }"
            :items-per-page="50"
            :loading="loading"
            :search="search"
            :options.sync="options"
            single-expand
            :expanded.sync="expanded"
            :headers="headers"
            :items="displayItems"
            item-key="jobId"
            sort-by="submitted"
            sort-desc>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status == 2" color="success" small>Processed</v-chip>
              <v-chip v-else-if="item.status == 1" color="info" small>Pending</v-chip>
              <v-chip v-else-if="item.status == 3" color="info" small>Pending</v-chip>
              <v-chip v-else-if="item.status == 4" color="info" small>Processing</v-chip>
              <v-chip v-else-if="item.status == 5" color="error" small>Error Verifying</v-chip>
              <v-chip v-else-if="item.status == 6" color="error" small>Failed With Errors</v-chip>
              <v-chip v-else-if="item.status == 7" color="error" small>Duplicate Log</v-chip>
              <v-chip v-else-if="item.status == 8" color="error" small>Callsign not registered</v-chip>
              <v-chip v-else-if="item.status == 9" color="error" small>Unknown Failure</v-chip>
              <v-chip v-else-if="item.status == -1 || item.status == -10" color="warning" small>Removed</v-chip>
              <v-chip v-else small>Unknown-{{ item.status }}</v-chip>
            </template>
            <template v-slot:[`item.submitted`]="{ item }">
              {{ item.submitted | dateTime }}
            </template>
            <template v-slot:[`item.processed`]="{ item }">
              {{ item.processed | dateTime }}
            </template>
            <template v-slot:[`item.reference`]="{ item }">
              <span><router-link :to="{ name: 'park', params: { id: item.reference } }">{{ item.reference }} {{ item.parkName }} ({{ item.location }})</router-link></span>
            </template>
            <template v-slot:[`item.firstQSO`]="{ item }">
              {{ item.firstQSO | dateTime }}
            </template>
            <template v-slot:[`item.lastQSO`]="{ item }">
              {{ item.lastQSO | dateTime }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <br/>
                    <v-row dense>
                      <v-col xs="12" sm="12" md="10" xl="11">
                        <v-row dense>
                          <v-col xs="6" sm="6" md="3" xl="2" class="text-right"><b>Uploaded By:</b></v-col>
                          <v-col xs="6" sm="6" md="3" xl="2">{{ item.uploadedByUser }}</v-col>
                          <v-col xs="6" sm="6" md="3" xl="2" class="text-right"><b>Original:</b></v-col>
                          <v-col xs="6" sm="6" md="3" xl="2">{{ item.userComment }}</v-col>
                          <v-col xs="6" sm="6" md="3" xl="2" class="text-right"><b>Park:</b></v-col>
                          <v-col xs="6" sm="6" md="3" xl="2">{{ item.reference }} {{ item.parkName }}</v-col>
                          <v-col xs="6" sm="6" md="3" xl="2" class="text-right"><b>Location:</b></v-col>
                          <v-col xs="6" sm="6" md="3" xl="2">{{ item.location }}</v-col>
                          <template v-if="item.md5sum">
                            <v-col xs="6" sm="6" md="3" xl="2" class="text-right"><b>MD5:</b></v-col>
                            <v-col xs="6" sm="6" md="3" xl="2">{{ item.md5sum }}</v-col>
                          </template>
                          <template v-if="isLogManager">
                            <v-col xs="6" sm="6" md="3" xl="2" class="text-right"><b>Job Id:</b></v-col>
                            <v-col xs="6" sm="6" md="3" xl="2">{{ item.jobId }}</v-col>
                          </template>
                          <v-col xs="6" sm="6" md="3" xl="2" class="text-right"><b>System:</b></v-col>
                          <v-col xs="6" sm="6" md="3" xl="2">
                            <template v-if="item.system == 0">stats</template>
                            <template v-else>pota.app</template>
                          </v-col>
                          <template v-if="item.deletedByUser">
                            <v-col xs="6" sm="6" md="3" xl="2" class="text-right"><b>Deleted By:</b></v-col>
                            <v-col xs="6" sm="6" md="3" xl="2">{{ item.deletedByUser }}</v-col>
                          </template>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                          <v-col cols="5">
                            <v-simple-table dense>
                              <thead>
                                <tr>
                                  <th>PHONE</th>
                                  <th>CW</th>
                                  <th>DATA</th>
                                  <th>Total</th>
                                  <th>Accepted</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{{ item.phone }}</td>
                                  <td>{{ item.cw }}</td>
                                  <td>{{ item.data }}</td>
                                  <td>{{ item.total }}</td>
                                  <td>{{ item.inserted }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-col>
                          <v-col></v-col>
                        </v-row>
                      </v-col>
                      <v-col xs="12" sm="12" md="2" xl="1" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-right'">
                        <div>
                        <support-dialog
                            topic="Log Uploading"
                            :tags="['log']"
                            :privateMessage="getSupportMessage(item)"
                            notes="A support ticket will be created for this log upload. It may take a few days for the support team to get back to you. We will have a copy of your log, but be sure to include any additional information about your activation or logging software that might help the team.">
                          <template #activator="{ on, attrs }">
                            <v-btn color="primary" outlined x-small v-bind="attrs" v-on="on"><v-icon left>{{$vuetify.icons.values.mdiLifebuoy}}</v-icon> Request Support</v-btn>
                          </template>
                        </support-dialog>
                        </div>
                        <div><v-btn v-if="item.logurl" color="secondary" outlined x-small :href="item.logurl"><v-icon left>{{$vuetify.icons.values.mdiDownload}}</v-icon> Download ADIF</v-btn></div>
                        <div><v-btn v-if="isTechAdmin && item.adminComment" color="secondary" outlined x-small :href="item.adminComment+'$3FfilterPattern$3DERROR'" target="_blank"><v-icon left>{{$vuetify.icons.values.mdiMagnify}}</v-icon> CloudWatch</v-btn></div>
                        <div><v-btn v-if="item.status > 0 && isLogManager" color="error" outlined x-small @click="remove(item.jobId)"><v-icon left>{{$vuetify.icons.values.mdiClose}}</v-icon> Remove Job</v-btn></div>
                      </v-col>
                    </v-row>
                    <!--<v-row v-if="item.total != item.inserted">
                      <v-col></v-col>
                      <v-col cols="5">
                        <v-alert dense outlined border="left" type="warning">
                          Duplicate QSOs or QSOs missing key data fields may not be accepted.
                        </v-alert>
                      </v-col>
                      <v-col></v-col>
                    </v-row>-->
                    <v-row v-if="job_details_loading">
                      <v-progress-linear color="primary" indeterminate></v-progress-linear>
                    </v-row>
                    <v-row v-if="job_details !== null && job_details.errors !== null && job_details.errors.length > 0">
                      <v-col cols="12">
                        <v-alert dense outlined border="left" type="error">
                          This log failed to import due to one or more errors. Please contact support for log removal. After removal, you can modify your log and try again.
                        </v-alert>
                        <v-alert dense text type="error">
                          <ul class="hang-indent">
                            <li v-for="error in job_details.errors" :key="error">{{ error }}</li>
                          </ul>
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row v-if="job_details !== null && job_details.warnings !== null && job_details.warnings.length > 0">
                      <v-col cols="12">
                        <v-alert dense text type="warning">
                          <ul class="hang-indent">
                            <li v-for="warning in job_details.warnings" :key="warning">{{ warning }}</li>
                          </ul>
                        </v-alert>
                      </v-col>
                    </v-row>
                    <!-- <v-row v-if="item.results !== null && item.results.infos !== null && item.results.infos.length > 0">
                      <v-alert v-if="item.results !== null && item.results.infos !== null && item.results.infos.length > 0" type="info">{{ item.results.infos.join() }}</v-alert>
                    </v-row> -->
                    <br/>
                </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<style scoped>
.horizontal-list {
  list-style-type: none;
  overflow: hidden;
}
.horizontal-list li.heading {
  font-weight: bold;
}
.horizontal-list li {
  float: left;
  margin-right: 0;
}
.horizontal-list-container {
  margin: 12px 0 12px -12px;
}
ul.hang-indent {
 padding: 0 0 0.75em 0; 
 margin: 0; 
 list-style: none;
 font-size: 0.9em;
}
ul.hang-indent li {
 text-indent: -0.75em; 
 margin: 0.33em 0.5em 0.5em 1.5em;
}
.wrap-buttons > button {
  display: block !important;
}
</style>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import LogUpload from '@/components/LogUpload'
import SupportDialog from '@/components/SupportDialog'

export default {
  beforeMount() {
  },
  mounted () {
    this.initialize()
  },
  beforeCreate() {
  },
  created() {
    this.initialize()
  },
  beforeUpdate() {
  },
  updated() {
  },
  methods: {
    initialize() {
      if (this.$route.query.search)
        this.search = this.$route.query.search;

      this.update();
    },
    update() {
      if (!this.needLoad && (this.loading || (this.items && this.items.length > 0)))
        return;

      this.needLoad = false;
      this.loading = true;

      this.error = null

      var params = ''

      if (this.userId != null && (this.isLogManager || this.isUserSupport))
        params = `?userId=${this.userId}`

      axios
        .get(`https://${process.env.VUE_APP_API_URL}/user/jobs${params}`, this.$store.getters.authTokenHeader)
        .then(response => response.data)
        .then(json => {
          this.count = json.length;

          var needReload = false;
          for (var i = 0; i < json.length; i++)
          {
            if (json[i].system == 1 && json[i].logpath)
              json[i].logurl = `https://${process.env.VUE_APP_API_URL}${json[i].logpath}`;
            
            // schedule reload if pending, uploading, or verifying
            needReload = needReload || json[i].status == 1 || json[i].status == 3 || json[i].status == 4;
          }
          this.items = json;
          this.loading = false;

          if (needReload)
          {
            this.needReload = true;

            setTimeout(() => {
              this.needReload = false;
              this.needLoad = true;
              this.update();
            }, 15000);
          }
        })
        .catch(err => {
          this.error = err.response.data
          this.items = []
          this.loading = false
        })
    },
    remove(jobId) {
        this.$dialog.confirm({ text: `Remove log upload job <b>${jobId}</b>?<br><br><small>NOTE: It may take up to 24 hours for the user and activation statistics to be fully updated.</small>` }).then(res => {
        if (!res) return;

        axios
          .post(`https://${process.env.VUE_APP_API_URL}/admin/jobs/remove/${jobId}`, {}, this.$store.getters.authTokenHeader)
          .then(() => {
            this.items = [];
            this.update();
          })
      })
    },
    getSupportMessage(job) {
      return `Callsign:  ${job.callsignUsed}
Park:      ${job.reference} ${job.parkName} (${job.location})
First QSO: ${dayjs.utc(job.firstQSO).format('YYYY-MM-DD HH:mm:ss')}
Last QSO:  ${dayjs.utc(job.lastQSO).format('YYYY-MM-DD HH:mm:ss')}
Log QSOs:  ${job.total}
Accepted:  ${job.inserted}

Job ID:    ${job.jobId}
MD5:       ${job.md5sum}
Log:       ${job.logurl}
Original:  ${job.userComment}
System:    ${job.system == 0 ? 'stats' : 'pota.app'}
Submitted: ${dayjs.utc(job.submitted).format('YYYY-MM-DD HH:mm:ss')}
Processed: ${dayjs.utc(job.processed).format('YYYY-MM-DD HH:mm:ss')}

Manage: ${window.origin}/#/user/logs/${job.userId}?search=${job.jobId}`;
    }
  },
  data () {
    return {
      needLoad: true,
      needReload: false,
      error: null,
      loading: false,
      overlay: false,
      showAll: false,
      options: {},
      search: '',
      count: 0,
      items: [],
      headers: [
          { value: 'status', text: 'Status', align: 'left' },
          { value: 'submitted', text: 'Submitted', align: 'left' },
          { value: 'processed', text: 'Processed', align: 'left' },
          { value: 'callsignUsed', text: 'Callsign', align: 'left' },
          { value: 'reference', text: 'Park', align: 'left', width: '25%', cellClass: 'text-no-wrap ellipsis' },
          { value: 'firstQSO', text: 'First QSO', align: 'left' },
          { value: 'lastQSO', text: 'Last QSO', align: 'left' },
          { value: 'total', text: 'Log QSOs', align: 'center' },
          { value: 'inserted', text: 'Accepted QSOs', align: 'center' },
          { value: 'jobId', text: 'Job ID', width: '0', align: ' d-none' }, // leading space is required to hide column
          { value: 'md5sum', text: 'MD5', width: '0', align: ' d-none' }, // leading space is required to hide column
          { value: 'userComment', text: 'Original File', width: '0', align: ' d-none' }, // leading space is required to hide column
          { text: '', value: 'data-table-expand' },
        ],
      
      user_callsigns: this.$store.state.user.callsigns,

      expanded: [],
      job_details_loading: false,
      job_details: null
    }
  },
  watch: {
      options: {
        handler () {
          this.update()
        },
        deep: true,
      },
      expanded: {
        handler () {
          this.job_details = null;

          if (this.expanded && this.expanded.length > 0)
          {
            var job = this.expanded[0];
            if (!job || job.system == 0)
              return;

            this.job_details_loading = true;

            var params = '';
            if (this.userId != null && (this.isLogManager || this.isUserSupport))
              params = `?userId=${this.userId}`;

            axios
              .get(`https://${process.env.VUE_APP_API_URL}/user/jobs/details/${job.jobId}${params}`, this.$store.getters.authTokenHeader)
              .then(response => response.data)
              .then(json => {
                this.job_details = json;
                this.job_details_loading = false;
              })
              .catch(err => {
                console.log(err);
                this.job_details = null;
                this.job_details_loading = false;
              });
          }
        },
        deep: true,
      },
    },
  props: [ 'userId' ],
  computed: {
    displayItems: {
      get() {
        if (this.showAll)
          return this.items;
        else
          return this.items.filter(item => item.status != -1);
      }
    },
    isAuthenticated: {
      get () {
        return this.$store.state.user.isAuthenticated
      }
    },
    isTechAdmin: {
      get () {
        return this.$store.state.user.isTechAdmin
      }
    },
    isLogManager: {
      get () {
        return this.$store.state.user.isLogManager
      }
    },
    isUserSupport: {
      get () {
        return this.$store.state.user.isUserSupport
      }
    },
    isLogUploader: {
      get () {
        return this.$store.state.user.isLogUploader
      }
    },
    isAlphaUser: {
      get () {
        return this.$store.state.user.isAlphaUser
      }
    },
    isBetaUser: {
      get () {
        return this.$store.state.user.isBetaUser
      }
    },
  },
  components: {
    LogUpload,
    SupportDialog
}
}
</script>
