<template>
    <v-form
            ref="form"
            v-model="form_valid"
          >
            <div @drop.prevent="addDropFiles" @dragover.prevent>
            <v-file-input
              v-model="files"
              color="black"
              counter
              label="Click here or drag and drop to upload ADIF logs from your activation"
              multiple
              outlined
              v-if="!upload_is_uploading"
              :show-size="1000"
              @change="verifyFileName"
              @click="clearResults">
              <template v-slot:selection="{ text }">
                <v-chip
                  color="amber darken-1"
                  dark
                  label
                  small>
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            </div>

            <v-container fluid v-if="files && files.length > 0">
              <!-- <v-row v-if="$vuetify.breakpoint.mdOnly" class="mb-3">
                <v-col cols="12"><hr/></v-col>
              </v-row> -->

              <!-- Large and up screen, display a header row like a table -->
              <v-row class="my-0 py-0" v-if="false">
                <v-col cols="2"><b>Filename</b></v-col>
                <v-col cols="2"><b>QSO Date(s)</b></v-col>
                <v-col cols="2"><b>Callsign</b></v-col>
                <v-col cols="2"><b>Park</b></v-col>
                <v-col cols="2"><b>Location</b></v-col>
                <v-col cols="2">
                  <v-row>
                      <v-col cols="2" class="min mx-1 px-1"></v-col>
                      <v-col cols="2" class="red mx-1 px-1"><b>Phone</b></v-col>
                      <v-col cols="2" class=" mx-1 px-1"><b>CW</b></v-col>
                      <v-col cols="2" class="green mx-1 px-1"><b>Data</b></v-col>
                      <v-col cols="2" class="yellow mx-1 px-1"><b>Total</b></v-col>
                      <v-col cols="2" class="blue min mx-1 px-1"></v-col>
                    </v-row>
                </v-col>
              </v-row>
              <v-row class="my-0 py-0" v-if="$vuetify.breakpoint.mdAndUp">
                <v-col cols="12" class="mb-3"><hr/></v-col>
              </v-row>
              <v-container fluid v-for="log in logs" :key="log.file.name" class="ma-0 pa-0">

                <!-- Medium screen, callsign/park/location -->
                <v-row class="" v-if="$vuetify.breakpoint.mdOnly">
                  <v-col cols="4" class="my-0 py-0"><b>Callsign:</b>
                    <v-text-field v-if="log.matchCallsign"
                        v-model="log.callsign"
                        outlined dense readonly filled>
                      </v-text-field>
                      <v-select v-else
                        v-model="log.callsign"
                        :items="user_callsigns"
                        outlined dense
                        return-object
                        single-line
                        :rules="callsign_rules">
                      </v-select>
                  </v-col>
                  <v-col cols="4" class="my-0 py-0"><b>Park:</b> 
                    <park-reference-entry
                          v-model="log.park" 
                          :state="log" 
                          :readonly="log.park_source != 'manual'"
                          :filled="log.park_source != 'manual'"
                          outlined dense
                          v-on:info="fileParkReference" 
                          required>
                        </park-reference-entry>
                  </v-col>
                  <v-col cols="4" class="my-0 py-0"><b>Location:</b> 
                    <v-text-field v-if="!log.invalidReference &&log.locations && log.locations.length <= 1"
                          v-model="log.location"
                          outlined dense readonly filled>
                        </v-text-field>
                        <v-select v-else
                          v-model="log.location"
                          :items="log.locations"
                          :readonly="log.invalidReference || !log.locations || log.locations.length <= 1"
                          :filled="log.invalidReference || !log.locations || log.locations.length <= 1"
                          outlined dense
                          return-object
                          single-line
                          :rules="location_rules">
                        </v-select>
                  </v-col>
                </v-row>

                <!-- Medium screen, filename, qso data, remove button -->
                <v-row no-gutters align="center" v-if="$vuetify.breakpoint.mdOnly">
                  <v-col cols="11"><b>Filename:</b> {{ log.file.name }} 
                    &nbsp;&nbsp;&nbsp;<b>QSO Dates:</b> {{ log.firstDate | date }}<template v-if="log.firstDate != log.lastDate">&nbsp;-&nbsp;{{ log.lastDate | date }}</template> 
                      &nbsp;&nbsp;&nbsp;<b>Phone:</b> {{ log.phone }} 
                      &nbsp;<b>CW:</b> {{log.cw}} 
                      &nbsp;<b>Data:</b> {{log.data}} 
                      &nbsp;<b>Total:</b> {{log.entries}} 
                  </v-col>
                  <v-col cols="1" class="text-right">
                    <v-btn icon plain color="error" @click="removeLog(log)"><v-icon>{{$vuetify.icons.values.mdiClose}}</v-icon></v-btn></v-col>
                </v-row>

                <!-- Medium screen, line break -->
                <v-row v-if="$vuetify.breakpoint.mdOnly">
                  <v-col cols="12" class="mb-3"><hr/></v-col>
                </v-row>

                <!-- Large and up screen, display a row like a table -->
                <v-row v-if="false">
                  <v-col cols="2">{{ log.file.name }}</v-col>
                  <v-col cols="2">{{ log.firstDate | date }}<template v-if="log.firstDate != log.lastDate"> {{ log.lastDate | date }}</template></v-col>
                  <v-col cols="2"><v-text-field v-if="log.matchCallsign"
                        v-model="log.callsign"
                        outlined dense readonly filled>
                      </v-text-field>
                      <v-select v-else
                        v-model="log.callsign"
                        :items="user_callsigns"
                        outlined dense
                        return-object
                        single-line
                        :rules="callsign_rules">
                      </v-select>
                  </v-col>
                  <v-col cols="2"><park-reference-entry
                          v-model="log.park" 
                          :state="log" 
                          :readonly="log.park_source != 'manual'"
                          :filled="log.park_source != 'manual'"
                          outlined dense
                          v-on:info="fileParkReference" 
                          required>
                        </park-reference-entry></v-col>
                  <v-col cols="2"><v-text-field v-if="!log.invalidReference &&log.locations && log.locations.length <= 1"
                          v-model="log.location"
                          outlined dense readonly filled>
                        </v-text-field>
                        <v-select v-else
                          v-model="log.location"
                          :items="log.locations"
                          :readonly="log.invalidReference || !log.locations || log.locations.length <= 1"
                          :filled="log.invalidReference || !log.locations || log.locations.length <= 1"
                          outlined dense
                          return-object
                          single-line
                          :rules="location_rules">
                        </v-select></v-col>
                  <v-col cols="2">
                    <!-- <b>Phone:</b>&nbsp;{{ log.phone }} <b>CW:</b>&nbsp;{{log.cw}} <b>Data:</b>&nbsp;{{log.data}} <b>Total:</b>&nbsp;{{log.entries}} -->
                    <v-row>
                      <v-col cols="2" class="min mx-1 px-1"></v-col>
                      <v-col class="red mx-1 px-1">{{ log.phone }}</v-col>
                      <v-col class="mx-1 px-1">{{ log.cw }}</v-col>
                      <v-col class="green mx-1 px-1">{{ log.data }}</v-col>
                      <v-col class="yellow mx-1 px-1">{{ log.entries }}</v-col>
                      <v-col class="blue text-right mx-1 px-1">
                        <v-btn icon plain color="error" @click="removeLog(log)"><v-icon>{{$vuetify.icons.values.mdiClose}}</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container> <!-- v-row for each row of submitted adif files -->
            </v-container> <!-- v-container for the group of submitted files -->



            <v-simple-table v-if="files && files.length > 0 && $vuetify.breakpoint.lgAndUp" class="table-vcenter">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Filename</th>
                    <th>QSO Date(s)</th>
                    <th>Callsign</th>
                    <th>Park</th>
                    <th>Location</th>
                    <th>Phone</th>
                    <th>CW</th>
                    <th>Data</th>
                    <th>Total</th>
                    <th class="min"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="log in logs" :key="log.file.name">
                    <td>{{ log.file.name }}</td>
                    <template v-if="log.error">
                    <td colspan="8"><b class="error--text">{{log.error}}</b></td>
                    </template>
                    <template v-else>
                    <td>{{ log.firstDate | date }}<template v-if="log.firstDate != log.lastDate">&nbsp;-&nbsp;{{ log.lastDate | date }}</template></td>
                    <td>
                      <v-text-field v-if="log.matchCallsign"
                        v-model="log.callsign"
                        outlined dense readonly filled>
                      </v-text-field>
                      <v-select v-else
                        v-model="log.callsign"
                        :items="user_callsigns"
                        outlined dense
                        return-object
                        single-line
                        :rules="callsign_rules">
                      </v-select>
                    </td>
                    <td class="width25">
                      <park-reference-entry
                        v-model="log.park" 
                        :state="log" 
                        :readonly="log.park_source != 'manual'"
                        :filled="log.park_source != 'manual'"
                        outlined dense
                        v-on:info="fileParkReference" 
                        required>
                      </park-reference-entry>
                    </td>
                    <td class="width15">
                      <v-text-field v-if="!log.invalidReference && log.locations && log.locations.length <= 1"
                        v-model="log.location"
                        outlined dense readonly filled>
                      </v-text-field>
                      <v-select v-else
                        v-model="log.location"
                        :items="log.locations"
                        :readonly="log.invalidReference || !log.locations || log.locations.length <= 1"
                        :filled="log.invalidReference || !log.locations || log.locations.length <= 1"
                        outlined dense
                        return-object
                        single-line
                        :rules="location_rules">
                      </v-select>
                    </td>
                    <td>{{log.phone}}</td>
                    <td>{{log.cw}}</td>
                    <td>{{log.data}}</td>
                    <td>{{log.entries}}</td>
                    </template>
                    <td><v-btn icon plain color="error" @click="removeLog(log)"><v-icon>{{$vuetify.icons.values.mdiClose}}</v-icon></v-btn></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <p></p>
            <p>
              <!--<em>Required fields:</em> <code>MODE</code>, and <code>BAND</code>.
              <em>Recommended fields:</em> <code>OPERATOR</code> or <code>STATION_CALLSIGN</code>, <code>MY_SIG_INFO</code> with park ID.
              <em>Preferred file naming convention:</em> <code>station_callsign@park_id-yyyymmdd.adif</code>.-->
              All files must be compliant with the ADIF specification, see <a href="https://adif.org">https://adif.org</a>.
              Please refer to the <a href="https://docs.pota.app/docs/activator_reference/ADIF_for_POTA_reference.html" target="blank">ADIF for POTA Technical Reference</a> for a list of required ADIF fields.
            </p>
            <p><strong>Need help?</strong> See the <a href="https://docs.pota.app/docs/activator_reference/submitting_logs.html" target="blank">Submitting Logs</a> section of the POTA Activator Reference or contact the support team by clicking the Request Support button.</p>

            <template v-if="files && files.length > 0">
            <v-checkbox v-model="agree_correct"><template v-slot:label>My logs are correct and I understand that logs cannot be modified once uploaded</template></v-checkbox>
            <v-checkbox v-model="agree_terms"><template v-slot:label>My logs are for activations that conform to the Parks on the Air&nbsp;<a href="https://docs.pota.app/docs/rules.html" target="blank">Rules</a>&nbsp;and&nbsp;<a href="https://docs.pota.app/docs/code_of_conduct.html" target="blank">Code of Conduct</a></template></v-checkbox>

            <v-btn 
              :disabled="!agree_correct || !agree_terms || upload_is_uploading || !form_valid || hasLogErrors || exceedsMaxUploadSize" 
              color="primary"
              class="mx-3 my-3"
              @click="verifyLog">
              Upload file for validation 
            </v-btn>
            <b class="error--text" v-if="!form_valid || hasLogErrors">One or more errors need to be corrected above before submitting logs.</b>
            <b class="error--text" v-if="exceedsMaxUploadSize">The maximum upload size is {{maxUploadSizeMB}}mb.</b>

            <template v-if="upload_is_uploading"><p>{{ this.upload_status_label }}</p></template>
            <v-progress-linear
              :indeterminate="upload_is_indeterminate"
              :value="upload_pct_value"
              :color="upload_progress_color"
              v-if="upload_is_uploading"
            ></v-progress-linear>
            </template>
          </v-form>
</template>

<style scoped>
.width10 {
  width: 10%;
}

.width15 {
  width: 15%;
}

.width25 {
  width: 25%;
}
.table-vcenter td {
  vertical-align: baseline;
}
</style>

<script>
import axios from 'axios'
import ParkReferenceEntry from '@/components/ParkReferenceEntry'

export default {
  beforeMount() {
  },
  mounted () {
    this.initialize()
  },
  beforeCreate() {
  },
  created() {
    this.initialize()
  },
  beforeUpdate() {
  },
  updated() {
  },
  methods: {
    initialize() {
      this.loadUserInfo();
    },
    loadUserInfo() {
      var userId = ''

      if (this.userId && this.isLogManager)
        userId = `?userId=${this.userId}`

      axios.get(`https://${process.env.VUE_APP_API_URL}/user/callsigns${userId}`, this.$store.getters.authTokenHeader)
        .then(response => response.data)
        .then(json => {
          this.user_callsigns = json.map(x => x.callsign);
          this.user_callsigns.unshift('');

          for (var c = 0; c < json.length; c++)
          {
            if (json[c].active == 1)
            {
              this.default_callsign = json[c].callsign;
              break;
            }
          }
          this.loading = false
        })
        .catch(err => {
          this.error = err.response.data
          this.loading = false
        });
    },
    clearResults () {
        this.log_submission_result = null
    },
    removeLog(log) {
      this.logs = this.logs.filter(x => x != log);
      this.files = this.files.filter(x => x != log.file);
    },
    changedFiles(files) {
      // when a user clicks to upload file(s), verify that they are good
      // files is the same as this.files

      // if there's an error, flag it later
      // var isErrors = false

      // Ensure that there are more than 1 file included
      if (files.length == 0) {
        return null
      }

      // Check all files
      for (var index = files.length - 1; index >= 0; index--) {
        // .name and .size might be important

        // debug
        //console.log(files[index]);
        //console.log(this.files)
        // console.log("here " + index)

        if (files[index] != null) {
          var testcond = files[index].name.match(/^([^@]+)@([A-Z0-9]+-[0-9]+)-(\d{4})(\d{2})(\d{2})\.(.*)/);
          if (testcond == null) {
            // isErrors = true;

          }
          // if (testcond.length < 7) {
          //   this.files.splice(index, 1)
          // }
          // testcond[0] // full filename
          // testcond[1] // callsign
          // testcond[2] // park
          // testcond[3] // year
          // testcond[4] // month
          // testcond[5] // day
          // testcond[6] // extention
          console.log(testcond)
          console.log(files[index].name)
        }
        
      }
    },
    parseFileName(file) {
      var result = {}
      var testcond = file.name.toUpperCase().match(/^([^@]+)@([A-Z0-9]+-?[0-9]+)-(\d{4})(\d{2})(\d{2})\.(.*)/)
      // testcond[0] // full filename
      // testcond[1] // callsign
      // testcond[2] // park
      // testcond[3] // year
      // testcond[4] // month
      // testcond[5] // day
      // testcond[6] // extention
      if (testcond && testcond.length == 7) {
        result.callsign = testcond[1]
        result.park = testcond[2]
            // this implementation doesn't seem to like 5 digit parks
            //.replace(/([A-Z\d]{1,2})(\d{4})/, '$1-$2')
        result.filedate = testcond[3] + '-' + testcond[4] + '-' + testcond[5]
      }
      return result
    },
    addDropFiles(e) {
      this.files = Array.from(e.dataTransfer.files);
      this.verifyFileName(this.files);
    },
    async verifyFileName(files) {
      // ensure that the filename is in the right format for uploading

      // debug
      //console.log(files)
      
      // if no files
      if (this.files == null || this.files.length == 0) {
        return 'Please upload a file'
      }

      this.logs = [];

      // if there are files
      for (var index = this.files.length - 1; index >= 0; index--) {
        var log = {};
        log.file = files[index];

        log.size = log.file.size;

        // filled later by park-reference-entry
        log.location = '';
        log.locations = [];

        var adif = await this.getFileContents(log.file);
        var info = this.scanLogContents(adif);
        var fileData = this.parseFileName(files[index])

        if (info)
        {
            log.callsign = null;
            if (info.station && info.station_count == 1)
              log.callsign = info.station;
            else if (info.operator && info.operator_count == 1)
              log.callsign = info.operator;
            else if (fileData.callsign)
              log.callsign = fileData.callsign

            log.matchCallsign = log.callsign && log.callsign.length > 0 && this.user_callsigns.includes(log.callsign);

            log.park_source = 'manual'
            log.park_adif = info.reference || null;
            log.park_filename = fileData.park || null;

            if (info.reference)
            {
              log.park_source = 'adif';
              log.park = info.reference;
            }
            else if (fileData.park)
            {
              log.park_source = 'filename';
              log.park = fileData.park;
            }

            log.invalidReference = true;
            log.hasReference = (log.park && log.park != '');

            if (!log.park)
              log.park = '';

            log.state = info.state;

            log.phone = info.modes.phone;
            log.cw = info.modes.cw;
            log.data = info.modes.data;
            log.entries = info.entries;

            log.firstDate = info.firstDate;
            log.lastDate = info.lastDate;

            log.error = info.error;
            
            if (!log.error)
            {
              if (log.callsign && !this.user_callsigns.includes(log.callsign))
                log.error = 'The callsign ' + log.callsign + ' is not registered to this account'
              else if (log.entries == 0)
                log.error = 'No QSOs found in log file'
            }
        }
        else
            log.error = 'This does not appear to be a valid ADIF log file'

        this.logs.push(log);

        this.$refs.form.validate();

        // set default location


        /*var testcond = files[index].name.match(/^([^@]+)@([A-Z0-9]+-[0-9]+)-(\d{4})(\d{2})(\d{2})\.(.*)/);
        // testcond[0] // full filename
        // testcond[1] // callsign
        // testcond[2] // park
        // testcond[3] // year
        // testcond[4] // month
        // testcond[5] // day
        // testcond[6] // extention
        if (testcond && testcond.length == 7) {
          this.files[index].callsign = testcond[1]
          this.files[index].park = testcond[2]
          this.files[index].filedate = testcond[3] + '-' + testcond[4] + '-' + testcond[5]
          console.log(this.files[index].filedate)
        } else {
          // figure out the lastModified date -> YYYY-MM-DD as a default
          var lm = new Date(this.files[index].lastModified)
          this.files[index].filedate = lm.toISOString().substring(0, 4) + '-' + lm.toISOString().substring(5, 7) + '-' + lm.toISOString().substring(8, 10)

          // set the callsign default for the callsign select drop down
          this.files[index].callsign = this.default_callsign
        }

        // bad file name
        if (testcond == null) {
          return 'The file name ' + files[index].name + ' is not a valid format for uploading. Please see the instructions above.'
        }

        // if not all the fields were detected
        if (testcond.length != 7) {
          return files[index].name + ' is not a valid format for uploading. Please see the instructions above.'
        }

        // if the extension isn't adi or adif
        if (testcond[6] != "adif" && testcond[6] != "adi" && testcond[6] != "ADI" && testcond[6] != "ADIF") {
          return files[index].name + ' must end in adi or adif'
        }

        // check for multi-location park
        await axios
        .get(`https://${process.env.VUE_APP_API_URL}/park/${testcond[2]}`, this.$store.getters.authTokenHeader)
        .then(response => response.data)
        .then(json => {
          this.tmp_locations = json.locationDesc.split(",")
          this.files[index].locations = json.locationDesc.split(",")
        })

        // this.files[index].locations = this.tmp_locations
        // if (this.tmp_locations.length > 0) {
        //   this.files[index].location = this.tmp_locations[0]
        // }
        // this.tmp_locations = null

        // console.log(this.files[index].locations)
        // console.log(this.files[index].location)

        // console.log(testcond)
        // this.files_data[index] = testcond
        */

      }

      return true
    },
    fileParkReference(info, state) {
      if (!info)
      {
        state.invalidReference = true;

        if (state.park_source == 'adif')
        {
          state.park_source = 'filename';
          state.park = state.park_filename || '';
        }
        else if (state.park_source == 'filename')
        {
          state.park_source = 'manual';
          state.park = '';
        }

        if (!state.park)
        {
          state.park_source = 'manual';
          state.park = '';
        }

        this.$refs.form.validate();

        return;
      }

      state.invalidReference = false;

      state.location = null;
      state.locations = info.locationDesc.split(',');

      if (state.locations.length > 0)
      {
        // check if we found a state in the adif file
        if (state.state)
        {
          for (var i = 0; i < state.locations.length; i++)
          {
            if (state.locations[i].endsWith('-' + state.state))
            {
              state.location = state.locations[i];
              break;
            }
          }
        }

        if (!state.location && state.locations.length > 1)
          state.locations.unshift('');

        // default to first location in list
        if (!state.location)
          state.location = state.locations[0];
      }

      this.$refs.form.validate();
    },
    // verifyParkLoc(park_id) {
    //   // verify that the files include parks that are single locations, or prompt the user for the location
    //   axios
    //     .get(`https://${process.env.VUE_APP_API_URL}/park/${park_id}`, this.$store.getters.authTokenHeader)
    //     .then(response => response.data)
    //     .then(json => {
    //       this.park_result = json;
    //       if (this.park_result != null) {
    //         if (this.park_result.description.includes(",")) {
    //           return this.park_result.description
    //         }
    //       }
    //     })
    //     .catch(err => {
    //       this.error = err.response.data
    //     })
    //     .then(() => { return false })
    // },
    verifyLog () {
      if (!this.$refs.form.validate())
        return;

      this.upload_is_uploading = true;
      this.upload_is_indeterminate = true;
      this.upload_status_label = "Uploading, please wait ..."
      this.clearResults();
      const formData = new FormData();

      for (let log of this.logs) {
        formData.append('adif', log.file);
        formData.append('reference', log.park);
        formData.append('location', log.location);
        formData.append('callsign', log.callsign)
      }

      //console.table([...formData]);

      var params = ''

      if (this.userId != null && this.userId != this.$store.state.user.userId && this.isLogManager)
        params = `?userId=${this.userId}`

      axios
        .post(`https://${process.env.VUE_APP_API_URL}/adif${params}`, formData, { 
          headers: { 'Authorization': this.$store.state.user.token, 'Content-Type': 'multipart/form-data' } 
        })
        .then((res) => {
          //console.log(res);
          this.log_submission_result = res;
          this.loading_validated_files = false;
          this.upload_status_label = "Uploaded successfully! Your log(s) will begin processing shortly. This page will refresh in 15 seconds.";
          this.upload_is_indeterminate = false;
          this.upload_pct_value = "100";

          setTimeout(() => {
            window.location.reload();
          }, 15000);
        })
        .catch((error) => {
          var msg = error.message == "Network Error" ?
            "There was a network error while uploading your log(s). Please check your internet connectivity and ensure there are no security/antivirus applications or browser plugins blocking your request." :
            "There was an error uploading your log(s). " + error.message;
          this.loading_validated_files = false;
          this.upload_status_label = msg;
          this.upload_is_indeterminate = false;
          this.upload_pct_value = "100";
          this.upload_progress_color = "error";
        });
    },
    getFileContents(file) {
        return new Promise((resolve, reject) => {
            let contents = ""
            const reader = new FileReader()
            reader.onloadend = function (e) {
                contents = e.target.result;
                resolve(contents);
            }
            reader.onerror = function (e) {
                reject(e);
            }
            reader.readAsText(file);
        });
    },
    scanLogContents(adif) {
      if (!adif)
        return null;

      if (adif.indexOf('<EOR>') < 0 && adif.indexOf('<eor>') < 0)
        return null;

      adif = adif.toUpperCase();

      var entries = this.countOccurrences(adif, "<EOR>");
      var station = this.findFirstOfField(adif, "STATION_CALLSIGN");
      var operator = this.findFirstOfField(adif, "OPERATOR");
      var reference = this.findFirstOfField(adif, "MY_SIG_INFO");
      var state = this.findFirstOfField(adif, "MY_STATE");
      var modes = this.countSubmodesAsModes(this.getUniqueFieldValues(adif, "MODE"));

      var dates = Object.keys(this.getUniqueFieldValues(adif, "QSO_DATE"));
      dates.sort();

      var firstDate = dates[0];
      var lastDate = dates[dates.length - 1];

      var error = null;

      if (!error)
      {
        var today = this.$dayjs().utc().format('YYYYMMDD');
        if (lastDate > today)
          error = `The QSO date '${lastDate}' seems to be from the future. Great Scott!`
      }

      if (!error)
      {
        if (reference)
        {
          var references = this.getUniqueFieldValues(adif, "MY_SIG_INFO");
          if (references && Object.keys(references).length > 1)
            error = "Multiple park references in MY_SIG_INFO are not supported";
        }
      }

      if (!reference)
        reference = '';

      if (!error)
      {
        var stations = this.getUniqueFieldValues(adif, "STATION_CALLSIGN");
        var station_keys = Object.keys(stations);
        var station_count = station_keys.length;
        var operator_count = Object.keys(this.getUniqueFieldValues(adif, "OPERATOR")).length;

        if (station_count > 1)
          error = "Only a single STATION_CALLSIGN value is supported per log file";
        else if (operator_count > 1 && station_count < 1)
          error = "STATION_CALLSIGN is required for logs with multiple operators";
        else if (operator_count > 1 && station_count == 1 && stations[station_keys[0]] != entries)
          error = "STATION_CALLSIGN is not provided for all QSOs in log";
      }

      if (!error)
      {
        var eoh = this.countOccurrences(adif, "<EOH>");
        if (eoh > 1)
          error = "More than one ADIF header (<EOH>) found in log file. Did you manually merge two logs?";
      }

      if (!error)
      {
        var bands = Object.keys(this.getUniqueFieldValues(adif, "BAND"));
        for (var i = 0; i < bands.length; i++)
        {
          if (this.validBands.includes(bands[i]))
            continue;

          error = `'${bands[i]}' is not a valid ADIF band value.`;

          if (!bands[i].endsWith('M'))
            error += " Are you missing a units specifier such as 'm' or 'cm'?"
        }
      }

      var info = {
        entries, station, station_count, operator, operator_count, reference, state, modes, firstDate, lastDate, error
      };

      //console.log(info);

      return info;
    },
    countOccurrences(string, token) {
      var count = 0;
      var pos = string.indexOf(token);

      while (pos >= 0)
      {
        count++;

        pos = string.indexOf(token, pos + token.length);
      }

      return count;
    },
    getUniqueFieldValues(adif, field) {
      var values = new Map();
      var token = '<' + field + ':';

      var pos = adif.indexOf(token);

      while (pos >= 0)
      {
        var value = this.parseFieldAtIndex(adif, pos);

        if (value && value.length > 0)
        {
          var count = 0;

          if (values.has(value))
            count = values.get(value);

          values.set(value, count + 1);
        }

        pos = adif.indexOf(token, pos + token.length);
      }

      return Object.fromEntries(values);
    },
    findFirstOfField(adif, field) {
      var pos = adif.indexOf('<' + field + ':');
      if (pos < 0)
        return null;

      return this.parseFieldAtIndex(adif, pos);
    },
    parseFieldAtIndex(adif, pos) {
      // assumes we are at start of <field:len>value
      pos = adif.indexOf(':', pos);
      if (pos < 0)
        return null;

      pos = adif.indexOf('>', pos);
      if (pos < 0)
        return null; // no end bracket?

      var len = 0;

      var next = adif.indexOf('<', pos + 1);
      if (next == -1) // no remaining tags in adif
        len = adif.length - pos - 1;
      else
        len = next - pos - 1;

      return adif.substring(pos + 1, pos + 1 + len).trim();
    },
    parseIntAtIndex(string, pos) {
      var str = '';

      while (pos < string.length)
      {
        var char = string.charAt(pos);

        // I didn't find an isDigit function...
        switch (char)
        {
          case '0':
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
          case '6':
          case '7':
          case '8':
          case '9':
            str += char;
            pos++;
            continue; // while
          default:
            break; // switch
        }

        break; // while
      }

      if (str.length == 0)
        return 0;

      return parseInt(str);
    },
    countSubmodesAsModes(submodes) {
      var modes = {
        phone: 0,
        cw: 0,
        data: 0
      };

      const modeMap = this.$store.state.adif.adifToPota;

      for (const [key, value] of Object.entries(submodes))
      {
        var mode = modeMap[key];
        switch (mode)
        {
        case 'PHONE':
          modes.phone += value;
          break;
        case 'CW':
          modes.cw += value;
          break;
        case 'DATA':
        default: // treat unknown as data
          modes.data += value;
          break;
        }
      }

      return modes;
    },
  },
  data () {
    return {
      form_valid: true,
      files: [],
      files_data: [],
      park_result: null,

      default_callsign: 'INVALID',
      user_callsigns: [],

      agree_correct: false,
      agree_terms: false,

      logs: [],

      tmp_locations: null,

      upload_status_label: "",
      upload_is_uploading: false,
      upload_is_indeterminate: false,
      upload_pct_value: null,
      upload_progress_color: "primary",

      // things we need to determine before a successful upload (pota.jobqueue table)
      upload_job_callsignused: null, // the callsign of the station uploading - state.callsigns / state.userid
      upload_job_parkid: null, // the parkid of the log being uploaded
      upload_job_reference: null, // the reference of the log being uploaded
      upload_job_locationid: null, // the location of the log being uploaded

      upload_rules: [
        // verify the filename
        v => this.verifyFileName(v) || "Unknown Error"
      ],

      callsign_rules: [
        value => !!value || 'Callsign is required',
        value => this.user_callsigns.includes(value) || 'Callsign not registered to account'
      ],

      location_rules: [
        value => !!value || 'Location is required',
      ],

      maxUploadSizeMB: 2,

      needLoad: true,
      error: null,
      loading: false,
      overlay: false,
      options: {},
      search: '',
      count: 0,
      items: [],
      headers: [
          { value: 'status', text: 'Status', align: 'left' },
          { value: 'submitted', text: 'Submitted', align: 'left' },
          { value: 'processed', text: 'Processed', align: 'left' },
          { value: 'callsignUsed', text: 'Callsign', align: 'left' },
          { value: 'reference', text: 'Park', align: 'left' },
          { value: 'firstQSO', text: 'First QSO', align: 'left' },
          { value: 'lastQSO', text: 'Last QSO', align: 'left' },
          { value: 'total', text: 'QSOs', align: 'center' },
          { text: '', value: 'data-table-expand' },
        ],
    }
  },
  watch: {
      options: {
        handler () {
          this.update()
        },
        deep: true,
      },
    },
  props: [ 'userId' ],
  computed: {
    validBands: {
      get () {
        return this.$store.state.adif.bands.map((band) => band.band.toUpperCase())
      }
    },
    isAuthenticated: {
      get () {
        return this.$store.state.user.isAuthenticated
      }
    },
    isLogManager: {
      get () {
        return this.$store.state.user.isLogManager
      }
    },
    isAlphaUser: {
      get () {
        return this.$store.state.user.isAlphaUser
      }
    },
    hasLogErrors: {
      get () {
        return this.logs.filter(x => x.error != null || x.invalidReference || !x.location).length > 0;
      }
    },
    totalFileSize: {
      get() {
        var size = 0;
        if (this.logs)
        {
          for (var log of this.logs)
            size += log.size;
        }
        return size;
      }
    },
    exceedsMaxUploadSize: {
      get () {
        return this.totalFileSize > (this.maxUploadSizeMB * 1024 * 1024);
      }
    }
  },
  components: {
      ParkReferenceEntry
  }
}
</script>
