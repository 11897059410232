var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuthenticated)?_c('v-layout',{attrs:{"row":"","wrap":""}},[(_vm.error)?[_c('h2',[_vm._v(_vm._s(_vm.error))])]:_vm._e(),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"128"}}),_c('h1',[_vm._v("Please wait...")])],1)]),_c('v-flex',{attrs:{"xs12":"","sm12":"","lg12":""}},[_c('v-card',[_c('v-card-title',[(_vm.activatorOnly)?_c('div',{staticClass:"text-h5 primary--text"},[_vm._v("Activation Log")]):_c('div',{staticClass:"text-h5 primary--text"},[_vm._v("Hunter Log")]),_c('v-spacer'),_c('filter-drop-menu',{attrs:{"name":"P2P Only","options":_vm.p2pOptions,"nocount":""},model:{value:(_vm.p2pOnly),callback:function ($$v) {_vm.p2pOnly=$$v},expression:"p2pOnly"}}),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.disableFilterControls},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date Range","readonly":"","outlined":"","hide-details":"","prepend-inner-icon":_vm.$vuetify.icons.values.mdiCalendar,"disabled":_vm.disableFilterControls},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}],null,false,1804986966),model:{value:(_vm.menuDateRange),callback:function ($$v) {_vm.menuDateRange=$$v},expression:"menuDateRange"}},[_c('v-date-picker',{attrs:{"range":"","scrollable":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateRange = []; _vm.menuDateRange = false; _vm.update()}}},[_vm._v("Cancel / Clear")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuDateRange = false; _vm.update()}}},[_vm._v("OK")])],1)],1),_c('v-spacer'),_c('v-text-field',{staticClass:"horizontal-list-container",attrs:{"label":"Search","single-line":"","outlined":"","hide-details":"","disabled":_vm.disableFilterControls},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSearch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{on:{"click":function($event){return _vm.doSearch()}}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.mdiMagnify))])]},proxy:true}],null,false,2144628036),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","disable-sort":"","disable-filtering":"","footer-props":{
            'items-per-page-options': [10, 25, 50, 100]
          },"items-per-page":25,"loading":_vm.loading,"options":_vm.options,"headers":_vm.logbookHeaders,"items":_vm.logbook,"server-items-length":_vm.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.tags",fn:function(ref){
          var item = ref.item;
return [(item.activator)?_c('v-chip',{attrs:{"label":"","x-small":"","color":"success"}},[_vm._v("Activator")]):_c('v-chip',{attrs:{"label":"","x-small":"","color":"orange"}},[_vm._v("Hunter")]),(item.p2pMatch)?_c('v-chip',{attrs:{"label":"","x-small":"","color":"accent"}},[_vm._v("P2P")]):_vm._e()]}},{key:"item.qsoDateTime",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.qsoDateTime))+" ")]}},{key:"item.station_callsign",fn:function(ref){
          var item = ref.item;
return [_c('user-stats',{attrs:{"callsign":item.station_callsign}})]}},{key:"item.operator_callsign",fn:function(ref){
          var item = ref.item;
return [_c('user-stats',{attrs:{"callsign":item.operator_callsign}})]}},{key:"item.worked_callsign",fn:function(ref){
          var item = ref.item;
return [_c('user-stats',{attrs:{"callsign":item.worked_callsign}})]}},{key:"item.mode",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.mode)+" ("+_vm._s(item.loggedMode)+") ")]}},{key:"item.reference",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'park', params: { id: item.reference } }}},[_vm._v(_vm._s(item.reference)+" "+_vm._s(item.name)+" "+_vm._s(item.parktypeDesc))])]}}],null,true)}),_c('small',[_c('p',[_vm._v("Your QSOs as a Hunter can be found on your "),_c('a',{on:{"click":function($event){_vm.activationReference=null; _vm.activationDate=null; _vm.hunterReference=null; _vm.search=null; _vm.dateRange=[]; _vm.update();}}},[_vm._v("My Hunter Log")]),_vm._v(" page. For QSOs as an Activator, go to your "),_c('router-link',{attrs:{"to":"/user/activations"}},[_vm._v("My Activations")]),_vm._v(" page, find your activation, and click "),_c('kbd',[_vm._v("View")]),_vm._v(".")],1)])],1)],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }