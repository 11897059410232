<template>
    <v-card flat>
        <v-alert v-if="!isAuthenticated" border="left" type="error">
            Please sign in before accessing this page!
        </v-alert>
        <template v-else>
            <v-form ref="form" v-model="valid" :disabled="disable">
                <v-container>
                    <v-layout wrap>
                        <v-flex xs12 sm3>
                            <v-select v-model="mycallsign" :items="callsigns" label="My Callsign"></v-select>
                        </v-flex>

                        <v-flex xs12 sm3>
                            <park-reference-entry v-model="park" @info="onParkInfo" required label="Park Reference">
                            </park-reference-entry>
                        </v-flex>
                        <v-flex xs12 sm3>
                            <v-select v-model="location" :items="parklocations" label="Location"
                                :disabled="!parklocations || parklocations.length == 0"></v-select>
                        </v-flex>

                        <v-flex xs12 sm3 align-self-center>
                            <v-row>
                                <v-spacer></v-spacer>
                                <support-dialog topic="Log Uploading" :tags="['log']"
                                    notes="A support ticket will be created. It may take a few days for the support team to get back to you.">
                                    <template #activator="{ on, attrs }">
                                        <v-btn color="primary" outlined small v-bind="attrs" v-on="on">
                                            <v-icon left>{{ $vuetify.icons.values.mdiLifebuoy }}</v-icon> Request
                                            Support
                                        </v-btn>
                                    </template>
                                </support-dialog>
                            </v-row>
                        </v-flex>

                        <v-flex xs12 xl12>
                            <v-divider></v-divider>
                        </v-flex>

                        <v-flex xs12 sm1 v-if="!extra"></v-flex>
                        <v-flex xs12 sm2>
                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="date" label="QSO Date (UTC)" :rules="dateRules" required
                                        readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex xs12 sm2>
                            <v-select v-model="band" @keyup.enter="addEntry()" :items="bands" label="Band"
                                :disabled="extra" :rules="bandRules"></v-select>
                        </v-flex>
                        <v-flex xs12 sm2 v-if="extra">
                            <v-text-field v-model.number="frequency" label="Frequency (kHz)" @keydown="onKeyDownNumber"
                                @keyup.enter="addEntry()" @input="updateBandFromFrequency" :rules="frequencyRules"
                                type="number" min="1000" :required="extra"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm2>
                            <v-select v-model="mode" @keyup.enter="addEntry()" :items="modes" item-text="name"
                                item-value="value" label="Mode" required></v-select>
                        </v-flex>
                        <v-flex xs12 sm3>
                            <v-text-field v-model="operator" @keyup.enter="addEntry()"
                                @keyup="operator = operator.toUpperCase()" :rules="operatorRules"
                                label="Operator (optional)"></v-text-field>
                        </v-flex>
                        <v-flex xs12 :sm1="extra" :sm2="!extra">
                            <v-switch v-model="extra" label="Extra"></v-switch>
                        </v-flex>

                        <v-flex xs12 sm1 v-if="!extra"></v-flex>
                        <v-flex xs12 sm1>
                            <v-text-field v-model.number="hour" label="Hour (UTC)" @keydown="onKeyDownNumber"
                                @input="updateTime" type="number" min="-1" max="24" :rules="timeRules"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm1>
                            <v-text-field v-model.number="minute" label="Minute" @keydown="onKeyDownNumber"
                                @keyup.enter="addEntry()" @input="updateTime" type="number" min="-1" max="60"
                                ref="mleTime" :rules="timeRules"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm2>
                            <v-text-field v-model="callsign" @keyup.enter="addEntry()"
                                @keyup="callsign = callsign.toUpperCase()" :rules="callsignRules" required
                                ref="mleCallsign" label="Callsign"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm1 v-if="extra">
                            <v-text-field v-model.number="rst_sent" label="RST Sent" @keydown="onKeyDownNumber"
                                @keyup.enter="addEntry()" type="number"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm1 v-if="extra">
                            <v-text-field v-model.number="rst_rcvd" label="RST Received" @keydown="onKeyDownNumber"
                                @keyup.enter="addEntry()" type="number"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm2>
                            <v-text-field v-model="state" @keyup.enter="addEntry()" @keyup="state = state.toUpperCase()"
                                label="State (recommended)"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm3>
                            <park-reference-entry v-model="p2p" label="Park-to-Park (optional)" @submit="addEntry()">
                            </park-reference-entry>
                        </v-flex>
                        <v-flex xs12 sm1 align-self-center>
                            <v-btn @click="addEntry()" :disabled="disable" color="orange accent-1 black--text"
                                elevation="0" small>Add Entry</v-btn>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-form>

            <v-data-table :headers="headers" hide-default-footer disable-pagination disable-sort :items="entries"
                item-key="id" :item-class="getEntryClass">
                <template #item.date="{ item }">
                    <v-edit-dialog @close="entryEdited(item)">
                        <template v-if="item.date && item.time">
                            {{ item.datetime }}
                        </template>
                        <template v-else>
                            [invalid]
                        </template>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                Date & Time (UTC)
                            </div>
                            <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition"
                                offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="item.date" :rules="dateRules" required readonly
                                        v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="item.date"></v-date-picker>
                            </v-menu>
                            <v-text-field v-model.number="item.hour" label="Hour" @keydown="onKeyDownNumber"
                                @input="updateItemTime(item)" type="number" min="-1" max="24"></v-text-field>
                            <v-text-field v-model.number="item.minute" label="Minute" @keydown="onKeyDownNumber"
                                @input="updateItemTime(item)" type="number" min="-1" max="60"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #item.callsign="{ item }">
                    <v-edit-dialog @close="entryEdited(item)">
                        <template v-if="item.callsign">
                            <user-stats :callsign="item.callsign"></user-stats>
                        </template>
                        <template v-else>
                            [invalid]
                        </template>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                Callsign
                            </div>
                            <v-text-field v-model="item.callsign" @keyup="item.callsign = item.callsign.toUpperCase()"
                                :rules="callsignRules" single-line autofocus required></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #item.state="{ item }">
                    <v-edit-dialog @close="entryEdited(item)">
                        {{ item.state }}
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                State
                            </div>
                            <v-text-field v-model="item.state" @keyup="item.state = item.state.toUpperCase()"
                                single-line autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #item.mode="{ item }">
                    <v-edit-dialog @close="entryEdited(item)">
                        <template v-if="item.submode">{{ item.submode }}</template>
                        <template v-else>{{ item.mode }}</template>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                Mode
                            </div>
                            <v-select v-model="item.entryMode" :items="modes" item-text="name" item-value="value"
                                required></v-select>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #item.band="{ item }">
                    <v-edit-dialog @close="entryEdited(item)">
                        {{ item.band }} <template v-if="item.frequency">({{ item.frequency }})</template>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                Band
                            </div>
                            <v-text-field v-model.number="item.frequency" label="Frequency (kHz)"
                                @keydown="onKeyDownNumber" :rules="frequencyRules" type="number" min="1000"
                                :required="extra" v-if="extra"></v-text-field>
                            <v-select v-model="item.band" :items="bands" v-else required></v-select>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #item.operator="{ item }">
                    <v-edit-dialog @close="entryEdited(item)">
                        <template v-if="item.operator">
                            <user-stats :callsign="item.operator"></user-stats>
                        </template>
                        <template v-else>
                            <user-stats :callsign="mycallsign"></user-stats>
                        </template>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                Operator
                            </div>
                            <v-text-field v-model="item.operator" @keyup="item.operator = item.operator.toUpperCase()"
                                :rules="callsignRules" single-line autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #item.p2p="{ item }">
                    <v-edit-dialog @close="entryEdited(item)">
                        <template v-if="extra">S:{{ item.rst_sent }} R:{{ item.rst_rcvd }} </template>{{ item.p2p }}
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                Details
                            </div>
                            <park-reference-entry v-model="item.p2p" label="Park-to-Park"></park-reference-entry>
                            <template v-if="extra">
                                <v-text-field v-model.number="item.rst_sent" label="RST Sent" @keydown="onKeyDownNumber"
                                    type="number"></v-text-field>
                                <v-text-field v-model.number="item.rst_rcvd" label="RST Received"
                                    @keydown="onKeyDownNumber" type="number"></v-text-field>
                            </template>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #item.remove="{ index }">
                    <v-btn icon small class="ma-2" color="error" @click="removeEntry(index)" :disabled="disable">
                        <v-icon>{{ $vuetify.icons.values.mdiClose }}</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <v-container>
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-checkbox v-model="agree_correct" :disabled="disable">
                                <template v-slot:label>
                                    My logs are correct and I understand that logs cannot be modified once uploaded
                                </template>
                            </v-checkbox>
                        </v-row>
                        <v-row>
                            <v-checkbox v-model="agree_terms" :disabled="disable">
                                <template v-slot:label>
                                    My logs are for activations that conform to the Parks on the Air Rules and Code of
                                    Conduct
                                </template>
                            </v-checkbox>
                        </v-row>

                        <v-row>
                            <v-btn
                                :disabled="!agree_correct || !agree_terms || entries.length == 0 || !this.mycallsign || !this.park || !this.location || hasInvalidEntries || disable"
                                color="primary" class="mx-3 my-3" @click="upload()">
                                Submit Log
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn @click="downloadAdif()"
                                :disabled="entries.length == 0 || !this.park || !this.location || hasInvalidEntries"
                                color="orange accent-1 black--text" class="mx-3 my-3">Download ADIF</v-btn>
                        </v-row>

                        <v-row>
                            <template v-if="disable">
                                <p>{{ this.upload_status_label }}</p>
                            </template>
                            <v-progress-linear :indeterminate="upload_is_indeterminate" :value="upload_pct_value"
                                :color="upload_progress_color" v-if="upload_is_uploading"></v-progress-linear>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-alert dense outlined border="left" type="warning">
                            <p><strong>This page is not intended for live logging!</strong> Once you leave this page
                                your log entries will be gone forever!</p>
                            Please make sure you submit or download your generated log before navigating away.
                        </v-alert>
                    </v-card-text>
                </v-card>

                <v-card flat>
                    <v-card-title>Tips for entering logs:</v-card-title>
                    <v-card-text>
                        <ul>
                            <li>Park references and callsigns will be automatically capitalized and formatted. (e.g.
                                us1234 &rarr; US-1234)</li>
                            <li>Hit the <b>Enter</b> key to add a log entry without clicking the <b>Add Entry</b>
                                button.</li>
                            <li>Use the <b>Up</b> and <b>Down</b> arrow keys for a faster way to adjust the minutes
                                between entries (hours will automatically roll over.)</li>
                            <li>A red entry means that one or more fields are incorrect. Click on a field in the log
                                entries table to edit.</li>
                            <li>Be sure to use the <b><i>Extra</i></b> fields if you need to upload your logs to other
                                sites such as <a href="https://lotw.arrl.org" target="_blank">ARRL's Logbook of the World</a> 
                                or <a href="https://qrz.com" target="_blank">QRZ</a>.</li>
                            <li>You can download your log file before submitting or at any later time by going to your
                                <router-link :to="{ name: 'user_logs' }">My Log Uploads</router-link> page.
                            </li>
                            <li><b>Multi-Park Uploaders:</b> As of now, you can only specify one park reference per upload, to handle a multi-park upload
                              download your ADIF file from the "<router-link :to="{ name: 'user_logs' }">My Log Uploads</router-link>" page, and replace the park reference with the subsequent park reference
                              and re-upload the file, you must do this for each additional park you've activated.
                            </li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-container>
        </template>
    </v-card>
</template>

<style scoped>
.min {
    width: 0;
    white-space: nowrap;
}
</style>

<script>
import ParkReferenceEntry from '@/components/ParkReferenceEntry'
import UserStats from '@/components/UserStats'
import SupportDialog from '@/components/SupportDialog'

import axios from 'axios'
import dayjs from 'dayjs'

export default {
    components: {
        ParkReferenceEntry,
        UserStats,
        SupportDialog
    },
    methods: {
        checkTime() {
            this.time = this.formatTime(this.time);

            if (this.time && this.time.length == 5)
                setTimeout(() => {
                    this.$refs.mleCallsign.$el.querySelector('input').select();
                });
        },
        formatTime(time) {
            if (!time)
                return time;
            return time.toUpperCase().replace(/([0-2]\d)([0-5]\d)/, '$1:$2');
        },
        isValidTime(time) {
            if (!time)
                return false;

            var input = time.replace(':', '');

            if (input.length != 4)
                return false;

            var hours = parseInt(input.substr(0, 2));
            var mins = parseInt(input.substr(2, 2));

            if (isNaN(hours) || hours < 0 || hours > 23 ||
                isNaN(mins) || mins < 0 || mins > 59)
                return false;

            return true;
        },
        isValidDate(date) {
            if (!date)
                return false;

            var input = date.replaceAll('-', '');

            if (input.length != 8)
                return false;

            var value = dayjs.utc(input);

            if (value > dayjs.utc())
                return false;

            return true;
        },
        isDateTimeInFuture() {
            var now = dayjs.utc();
            var datetime = dayjs.utc(this.date + ' ' + this.asTime(this.hour, this.minute));
            return datetime > now;
        },
        updateTime() {
            if (!this.isInteger(this.minute))
                this.minute = 0;
            if (!this.isInteger(this.hour))
                this.hour = 0;
            if (this.date == null || this.date == '')
                this.date = dayjs().utc().format('YYYY-MM-DD');

            if (this.minute <= -1) {
                this.minute = 59;
                this.hour--;
            }
            if (this.hour <= -1) {
                this.hour = 23;
                this.date = dayjs.utc(this.date).subtract(1, 'day').format('YYYY-MM-DD');
            }
            if (this.minute >= 60) {
                this.minute = 0;
                this.hour++;
            }
            if (this.hour >= 24) {
                this.hour = 0;
                this.date = dayjs.utc(this.date).add(1, 'day').format('YYYY-MM-DD');
            }
        },
        asTime(hour, minute, separator = '') {
            return String(hour).padStart(2, '0') + separator + String(minute).padStart(2, '0');
        },
        onKeyDownNumber(event) {
            if (this.isKeyAlpha(event.code)) {
                event.preventDefault();
                return;
            }
        },
        isKeyAlpha(code) {
            switch (code) {
                case "KeyA":
                case "KeyB":
                case "KeyC":
                case "KeyD":
                case "KeyE":
                case "KeyF":
                case "KeyG":
                case "KeyH":
                case "KeyI":
                case "KeyJ":
                case "KeyK":
                case "KeyL":
                case "KeyM":
                case "KeyN":
                case "KeyO":
                case "KeyP":
                case "KeyQ":
                case "KeyR":
                case "KeyS":
                case "KeyT":
                case "KeyU":
                case "KeyV":
                case "KeyW":
                case "KeyX":
                case "KeyY":
                case "KeyZ":
                    return true;
                default:
                    return false;
            }
        },
        isInteger(string) {
            if (string == null || string.length == 0)
                return false;

            var pos = 0;

            while (pos < string.length) {
                var char = string.charAt(pos);

                // I didn't find an isDigit function...
                switch (char) {
                    case '0':
                    case '1':
                    case '2':
                    case '3':
                    case '4':
                    case '5':
                    case '6':
                    case '7':
                    case '8':
                    case '9':
                        continue; // while
                    default:
                        return false;
                }
            }

            return true;
        },
        nextControl(event) {
            try {
                var nextInput = event.target.parentElement.nextElementSibling.childNodes[0];
                nextInput.focus();
            } catch (error) {
                console.log(error)
            }
        },
        updateBandFromFrequency() {
            if (this.frequency == null || this.frequency == '') {
                this.band = null;
                return;
            }

            var freq = parseFloat(this.frequency);
            if (isNaN(freq)) {
                this.band = null;
                return;
            }

            freq = freq / 1000; // kHz to MHz

            var bands = this.adifBands.filter(x => x.low <= freq && x.high >= freq);
            if (bands.length == 0) {
                this.band = null;
                return;
            }

            this.band = bands[0].band.toUpperCase();
        },
        addEntry() {
            if (!this.$refs.form.validate())
                return;

            var id = this.entries.length + 1;

            var operator = this.operator;
            if (!operator || operator.length == 0)
                operator = this.mycallsign;

            var parts = this.mode.split(':');
            var mode = parts[0];
            var submode = null;
            if (parts.length > 1)
                submode = parts[1];

            var entry = {
                id,
                date: this.date,
                time: this.asTime(this.hour, this.minute),
                hour: this.hour,
                minute: this.minute,
                datetime: this.date + ' ' + this.asTime(this.hour, this.minute, ':'), // display and sort
                callsign: this.callsign,
                state: this.state,
                band: this.band,
                frequency: this.extra ? this.frequency : null,
                rst_sent: this.extra ? this.rst_sent : null,
                rst_rcvd: this.extra ? this.rst_rcvd : null,
                entryMode: this.mode,
                mode: mode,
                submode: submode,
                operator: operator,
                p2p: this.p2p,
                remove: '',
                invalid: false
            };

            this.entries.push(entry);

            this.entries.sort((a, b) => a.datetime.localeCompare(b.datetime));

            for (var i = 0; i < this.entries.length; i++)
                this.entries[i].id = i + 1;

            this.callsign = '';
            this.state = '';
            this.p2p = '';

            setTimeout(() => {
                this.$refs.mleTime.$el.querySelector('input').select();
            });
        },
        updateItemTime(item) {
            if (!this.isInteger(item.minute))
                item.minute = 0;
            if (!this.isInteger(item.hour))
                item.hour = 0;
            if (item.date == null || item.date == '')
                item.date = dayjs().utc().format('YYYY-MM-DD');

            if (item.minute <= -1) {
                item.minute = 59;
                item.hour--;
            }
            if (item.hour <= -1) {
                item.hour = 23;
                item.date = dayjs.utc(item.date).subtract(1, 'day').format('YYYY-MM-DD');
            }
            if (item.minute >= 60) {
                item.minute = 0;
                item.hour++;
            }
            if (item.hour >= 24) {
                item.hour = 0;
                item.date = dayjs.utc(item.date).add(1, 'day').format('YYYY-MM-DD');
            }
        },
        entryEdited(item) {
            if (!item.operator)
                item.operator = this.mycallsign;

            if (!this.isValidDate(item.date)) {
                item.invalid = true;
                return;
            }

            if (!this.isInteger(item.hour) || !this.isInteger(item.minute)) {
                item.invalid = true;
                return;
            }

            item.time = this.asTime(item.hour, item.minute);
            item.datetime = item.date + ' ' + this.asTime(item.hour, item.minute, ':'); // display and sort

            if (!item.entryMode) {
                item.invalid = true;
                return;
            }

            var parts = item.entryMode.split(':');
            item.mode = parts[0];
            if (parts.length > 1)
                item.submode = parts[1];
            else
                item.submode = null;

            if (this.extra) {
                if (item.frequency == null || item.frequency == '') {
                    item.band = null;
                    item.invalid = true;
                    return;
                }

                var freq = parseFloat(item.frequency);
                if (isNaN(freq)) {
                    item.band = null;
                    item.invalid = true;
                    return;
                }

                freq = freq / 1000; // kHz to MHz

                var bands = this.adifBands.filter(x => x.low <= freq && x.high >= freq);
                if (bands.length == 0) {
                    item.band = null;
                    item.invalid = true;
                    return;
                }

                item.band = bands[0].band.toUpperCase();
            }

            if (!item.band) {
                item.invalid = true;
                return;
            }

            if (!item.callsign || !this.$store.state.global.validCallsignRegex.test(item.callsign)) {
                item.invalid = true;
                return;
            }

            item.invalid = false;

            this.entries.sort((a, b) => a.datetime.localeCompare(b.datetime));
        },
        getEntryClass(item) {
            if (!item || item.invalid)
                return 'red--text';
            return '';
        },
        removeEntry(index) {
            this.entries = this.entries.filter((_, i) => index != i);

            for (var i = 0; i < this.entries.length; i++)
                this.entries[i].id = i + 1;

            this.adif = this.generateAdifString();
        },
        downloadAdif() {
            if (this.entries.length == 0)
                return;

            var adif = this.generateAdifString();
            var filename = `${this.mycallsign}@${this.park}-${this.date.replaceAll('-', '')}.adi`;

            var blob = new Blob([adif], { type: 'application/text' });
            var url = URL.createObjectURL(blob);

            // Create a link to download it
            var pom = document.createElement('a');
            pom.href = url;
            pom.setAttribute('download', filename);
            pom.click();
        },
        generateAdifString() {
            var my_state = null;
            if (this.location && this.location.includes('-'))
                my_state = this.location.split('-')[1];

            var adif = this.generateAdifTag('ADIF_VER', "3.1.2") + '\n';
            adif += this.generateAdifTag('PROGRAMID', 'POTA') + '\n';
            adif += this.generateAdifTag('PROGRAMVERSION', '1') + '\n';
            adif += '<EOH>\n\n';

            for (var i = 0; i < this.entries.length; i++) {
                var entry = this.entries[i];

                adif += this.generateAdifTag('QSO_DATE', entry.date.replaceAll('-', '')) +
                    this.generateAdifTag('TIME_ON', entry.time.replace(':', '')) +
                    this.generateAdifTag('STATION_CALLSIGN', this.mycallsign) +
                    this.generateAdifTag('OPERATOR', entry.operator) +
                    this.generateAdifTag('CALL', entry.callsign) +
                    this.generateAdifTag('STATE', entry.state) +
                    this.generateAdifTag('BAND', entry.band) +
                    this.generateAdifTag('MODE', entry.mode) +
                    this.generateAdifTag('SUBMODE', entry.submode) +
                    this.generateAdifTag('MY_SIG', 'POTA') +
                    this.generateAdifTag('MY_SIG_INFO', this.park) +
                    this.generateAdifTag('MY_STATE', my_state);

                if (this.extra) {
                    var freq = parseFloat(entry.frequency) / 1000;
                    adif += this.generateAdifTag('FREQ', String(freq)) +
                        this.generateAdifTag('RST_SENT', entry.rst_sent) +
                        this.generateAdifTag('RST_RCVD', entry.rst_rcvd);
                }
                else {
                    adif += this.generateAdifTag('RST_SENT', entry.mode == 'CW' ? '599' : '59') +
                        this.generateAdifTag('RST_RCVD', entry.mode == 'CW' ? '599' : '59');
                }

                if (entry.p2p != '')
                    adif += this.generateAdifTag('SIG', 'POTA') +
                        this.generateAdifTag('SIG_INFO', entry.p2p);

                adif += '<EOR>\n';
            }

            return adif;
        },
        generateAdifTag(tag, value) {
            if (!value)
                return '';
            //coerce to string for any non-string values.
            value = value.toString();
            return `<${tag}:${value.length}>${value} `;
        },
        onParkInfo(info) {
            if (!info) {
                this.parklocations = [];
                return;
            }

            var locations = info.locationDesc.split(',');
            locations.sort();

            this.parklocations = locations;

            if (this.parklocations.length > 0)
                this.location = this.parklocations[0];
            else
                this.location = '';
        },
        upload() {
            if (!this.mycallsign || !this.park || !this.location)
                return;

            this.disable = true;

            this.upload_is_uploading = true;
            this.upload_is_indeterminate = true;
            this.upload_status_label = "Uploading, please wait ..."

            // create a file object to upload in FormData
            var file = new File([this.generateAdifString()], `${this.mycallsign}@${this.park}-${this.date.replaceAll('-', '')}.adi`, { type: 'application/text' });

            const formData = new FormData();

            formData.append('adif', file);
            formData.append('reference', this.park);
            formData.append('location', this.location);
            formData.append('callsign', this.mycallsign)

            //console.table([...formData]);

            var params = ''

            if (this.userId != null && this.userId != this.$store.state.user.userId && this.isLogManager)
                params = `?userId=${this.userId}`

            axios
                .post(`https://${process.env.VUE_APP_API_URL}/adif${params}`, formData, {
                    headers: { 'Authorization': this.$store.state.user.token, 'Content-Type': 'multipart/form-data' }
                })
                .then((res) => {
                    //console.log(res);
                    this.log_submission_result = res;
                    this.loading_validated_files = false;
                    this.upload_status_label = "Uploaded successfully! Your log will begin processing shortly. You will be redirected to the log uploads page in 15 seconds.";
                    this.upload_is_indeterminate = false;
                    this.upload_pct_value = "100";

                    setTimeout(() => {
                        if (this.userId != null && this.userId != this.$store.state.user.userId && this.isLogManager)
                            this.$router.push({ name: 'user_logs_admin', params: { userId: this.userId } });
                        else
                            this.$router.push({ name: 'user_logs' });
                    }, 15000);
                })
                .catch((error) => {
                    var msg = error.message == "Network Error" ?
                        "There was a network error while uploading your log. Please check your internet connectivity and ensure there are no security/antivirus applications or browser plugins blocking your request." :
                        "There was an error uploading your log. " + error.message;
                    msg += "\n\nPlease download your log to upload it manually or send it to support.";
                    this.loading_validated_files = false;
                    this.upload_status_label = msg;
                    this.upload_is_indeterminate = false;
                    this.upload_pct_value = "100";
                    this.upload_progress_color = "error";
                });
        }
    },
    props: ['userId'],
    data() {
        return {
            valid: true,
            disable: false,
            menu: false,

            agree_correct: false,
            agree_terms: false,

            mycallsign: this.$store.state.user.callsign,
            park: '',
            location: '',

            parklocations: [],

            date: dayjs().utc().format('YYYY-MM-DD'),
            hour: 0,
            minute: 0,
            callsign: '',
            band: '20M',
            mode: 'CW',
            state: '',
            operator: '',
            p2p: '',
            entries: [],

            extra: false,
            frequency: '',
            rst_sent: '599',
            rst_rcvd: '599',

            headers: [
                { text: '#', value: 'id', align: 'left', sortable: true, class: 'text-xs-left min' },
                { text: 'Date/Time (UTC)', value: 'date', align: 'left', sortable: true, class: 'text-xs-left' },
                //{ text: 'Time', value: 'time', align: 'left', sortable: true, class: 'text-xs-left' },
                { text: 'Callsign', value: 'callsign', align: 'left', sortable: true, class: 'text-xs-left' },
                { text: 'State', value: 'state', align: 'left', sortable: true, class: 'text-xs-left' },
                { text: 'Band', value: 'band', align: 'left', sortable: true, class: 'text-xs-left' },
                { text: 'Mode', value: 'mode', align: 'left', sortable: true, class: 'text-xs-left' },
                { text: 'Operator', value: 'operator', align: 'left', sortable: true, class: 'text-xs-left' },
                { text: 'Details', value: 'p2p', align: 'left', sortable: true, class: 'text-xs-left' },
                { text: '', value: 'remove', align: 'right', sortable: false, class: 'text-xs-right px-0 min' },
            ],

            mycallsignRules: [
                v => !!v || 'Example: N0CALL',
                v => this.$store.state.global.validCallsignRegex.test(v) || 'Example: N0CALL'
            ],
            parkRules: [
                v => !v || this.$store.state.global.validReferenceRegex.test(v) || 'Example: US-0001'
            ],
            dateRules: [
                v => !!v || 'YYYY-MM-DD',
                // eslint-disable-next-line
                v => !this.isDateTimeInFuture() || 'Future?',
                v => this.isValidDate(v) || 'Valid date in the past is required'
            ],
            timeRules: [
                v => v != null || 'Time is required',
                // eslint-disable-next-line
                v => !this.isDateTimeInFuture() || 'Future?'
            ],
            callsignRules: [
                v => !!v || 'Example: N0CALL',
                v => this.$store.state.global.validCallsignRegex.test(v) || 'Example: N0CALL'
            ],
            operatorRules: [
                v => !v || this.$store.state.global.validCallsignRegex.test(v) || 'Example: N0CALL'
            ],
            p2pRules: [
                v => !v || this.$store.state.global.validReferenceRegex.test(v) || 'Example: US-0001'
            ],
            frequencyRules: [
                v => (this.extra && !!v) || 'Frequeuncy is required',
            ],
            bandRules: [
                v => !!v || 'Band is required',
            ],

            upload_status_label: "",
            upload_is_uploading: false,
            upload_is_indeterminate: false,
            upload_pct_value: null,
            upload_progress_color: "primary",
        }
    },
    computed: {
        bands: {
            get() {
                return this.$store.state.adif.allBands;
            }
        },
        adifBands: {
            get() {
                return this.$store.state.adif.bands;
            }
        },
        submodes: {
            get() {
                return this.$store.state.adif.allSubmodes;
            }
        },
        callsigns: {
            get() {
                return this.$store.state.user.callsigns;
            }
        },
        modes: {
            get() {
                return this.$store.state.adif.submodeList;
            }
        },
        isAuthenticated: {
            get() {
                return this.$store.state.user.isAuthenticated
            }
        },
        isLogManager: {
            get() {
                return this.$store.state.user.isLogManager
            }
        },
        hasInvalidEntries: {
            get() {
                return this.entries.some(entry => entry.invalid);
            }
        },
    },
}
</script>
