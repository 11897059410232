<template>
  <v-layout row wrap v-if="isAuthenticated">
    <template v-if="info">
    <v-flex xs12 md2>
      <v-card elevation=0 v-if="info.emailAddress">
        <v-avatar rounded size=250>
          <v-img width=250 height=250 :src="`https://www.gravatar.com/avatar/${gravatar}?s=250&d=identicon`"></v-img>
        </v-avatar>
        <v-card-text>
          <small>
            <p>Want to change your user profile picture? Create an account at <a href="https://gravatar.com/">Gravatar</a> for your account email!</p>
            <p>NOTE: We know that using Gravatar for profile pictures is going to be a controversial feature. We are hard at work creating an option for users to upload profile photos directly to POTA, but this will take some time!</p>
          </small>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex xs12 md4>
      <v-flex>
      <v-card elevation=0>
        <v-card-title class="headline green white--text" primary-title>
          Preferences
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <small><b class="success--text">ATTENTION:</b> It may take up to an hour for your changes to show up on your profile page.</small>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="info.fullname" label="Name" :rules="nameRules" required persistent-hint hint="This is the name that will appear on awards and notifications."></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="info.displayName" label="Display Name" persistent-hint hint="This is the name that will appear on the public portions of the website and in your profile. You may use your full name, first name, a nickname, or no name at all."></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="info.qth" label="QTH" persistent-hint hint="Let other POTA users know where you are from! This is optional, but we encourage providing at least your country, state, or nearest major city."></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="info.emailAddress" label="Email" :rules="emailRules" required persistent-hint hint="If account changes or new login methods are requested which require approval, this is the email we will use to verify your identity."></v-text-field>
                </v-flex>
                <!--
                <v-flex xs12>
                  <v-checkbox v-model="info.awardOptIn" label="Send me award certificates"></v-checkbox>
                  <v-checkbox v-model="info.phoneOptIn" label="Opt in to phone-specific awards"></v-checkbox>
                  <v-checkbox v-model="info.cwOptIn" label="Opt in to CW-specific awards"></v-checkbox>
                  <v-checkbox v-model="info.digitalOptIn" label="Opt in to digital-specific awards"></v-checkbox>
                  <v-checkbox v-model="info.bandOptIn" label="Opt in to band-specific awards"></v-checkbox>
                </v-flex>
                -->
              </v-layout>
            </v-container>
          </v-form>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-chip color="success" outlined v-if="saved">Saved!</v-chip>
          <v-progress-circular indeterminate color="green" v-if="saving"></v-progress-circular>
          <v-btn color="green white--text" @click="save" v-if="!saving">Save</v-btn>
        </v-card-actions>
      </v-card>
      </v-flex>
    </v-flex>

    <v-flex xs12 md6>
      <v-flex v-if="callsigns">
      <v-card elevation=0>
        <v-card-title class="headline green white--text" primary-title>
          Callsigns
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Callsign</th>
                <th class="text-left">Valid Period</th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in callsigns" :key="item.id">
                <th>{{ item.callsign }} <v-chip x-small class="ma-1 text-uppercase" color="success" v-if="item.active">Primary</v-chip></th>
                <td><span v-if="item.startDate">{{ item.startDate }}</span><span v-else>[no start date]</span> to <span v-if="item.endDate">{{ item.endDate }}</span><span v-else>[no end date]</span> <v-chip x-small class="ma-1 text-uppercase" color="warning" v-if="item.warning">{{ item.warning }}</v-chip></td>
                <td class="text-right">
                  <v-btn x-small outlined class="ma-1" color="success" @click="active_callsign(item.callsign)" v-if="item.valid && !item.active">Set Primary</v-btn>
                  <v-btn x-small outlined class="ma-1" color="error" @click="remove_callsign(item.callsign)" v-if="!item.active">Remove</v-btn>
                  <v-btn x-small outlined class="ma-1" @click="edit_callsign(item.callsign)">Edit</v-btn>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3" class="text-right"><v-btn x-small outlined class="ma-1 green white--text" @click="add_callsign">Add Callsign</v-btn></td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <v-card-text>
          <small>
            <p>NOTE: Your primary callsign is the callsign that will be displayed on your user profile and award certificates. You may need to logout and login after you change your primary callsign. It may take several hours for the change to be seen throughout POTA.</p>
          </small>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex v-if="groups && (isUserSupport || groups.length > 0)">
      <v-card elevation=0>
        <v-card-title class="headline green white--text" primary-title>
          Groups
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Group</th>
                <th class="text-left">Description</th>
                <th class="text-left">Assigned By</th>
                <th class="text-center" v-if="isUserManager"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in groups" :key="item.name">
                <th>{{ item.name }}</th>
                <td>{{ item.description }}</td>
                <td>{{ item.assignedBy }} on {{ item.assignedDate | date }}</td>
                <td class="text-right" v-if="isUserManager">
                  <v-btn x-small outlined class="ma-1" color="error" @click="revoke_group(item.name)">Revoke</v-btn>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="isUserManager">
              <tr>
                <td colspan="4" class="text-right"><v-btn x-small outlined class="ma-1 green white--text" @click="open_assign_group">Assign Group</v-btn></td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card>
    </v-flex>

    <v-flex v-if="isUserManager">
      <v-card elevation=0>
        <v-card-title class="headline green white--text" primary-title>
          Account Status
        </v-card-title>
        <v-card-text>
          <br/>
          <p>
            <v-btn :color="info.active ? 'error' : 'success'" :loading="changing_active" @click="toggle_active">
              <template v-if="info.active == 1">Mark as Inactive</template>
              <template v-else>Mark as Active</template>
            </v-btn>
          </p>
          <p>The user's account status will not change until the next time the user attempts to autheticate.</p>
        </v-card-text>
      </v-card>
    </v-flex>

    </v-flex>
    </template>
    <template v-else-if="error">
      <h2>{{ error }}</h2>
    </template>
    <template v-else>
      <div class="text-center">
        <v-progress-circular :size="100" :width="10" color="primary" indeterminate></v-progress-circular>
      </div>
    </template>

    <v-dialog v-model="callsignDialog" max-width="600">
      <v-card>
        <v-card-title class="headline green white--text" primary-title>
          <span v-if="callsignEdit">Edit Callsign</span>
          <span v-else>Add Callsign</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field v-model="callsign" label="Callsign" :rules="callsignRules" required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        :prepend-icon="$vuetify.icons.values.mdiCalendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="startDateRules"
                        persistent-hint
                        hint="First date that this callsign is valid."
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @input="menuStartDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        :prepend-icon="$vuetify.icons.values.mdiCalendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        persistent-hint
                        hint="Last date that this callsign is valid. If this is your current callsign, you can leave this field blank."
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @input="menuEndDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="callsignDialog = false">Cancel</v-btn>
          <v-btn color="success" @click="save_callsign">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="groupsDialog" max-width="1000">
      <v-card>
        <v-card-title class="headline green white--text" primary-title>
          <span>Assign Group</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table v-if="allGroups">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Group</th>
                <th class="text-left">Description</th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in allGroups" :key="item.name">
                <th>{{ item.name }}</th>
                <td>{{ item.description }}</td>
                <td class="text-right">
                  <v-btn x-small outlined class="ma-1" color="success" @click="assign_group(item.name)">Assign</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
          </v-simple-table>
          <template v-else>
            <div class="text-center">
              <v-progress-circular :size="100" :width="10" color="primary" indeterminate></v-progress-circular>
            </div>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="groupsDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import axios from 'axios'
import md5 from 'blueimp-md5'

export default {
  beforeMount() {
  },
  mounted () {
  },
  beforeCreate() {
  },
  created() {
    this.updatePreferences()
  },
  beforeUpdate() {
  },
  updated() {
    this.updatePreferences()
  },
  methods: {
    updatePreferences() {
      if (!this.needLoad && this.info && !this.error)
        return

      this.needLoad = false
      this.error = null
      this.info = null
      this.callsigns = null

      var userId = ''
      if (this.userId != null && this.isUserSupport)
        userId = `?userId=${this.userId}`

      var requestInfo = axios.get(`https://${process.env.VUE_APP_API_URL}/user/info${userId}`, this.$store.getters.authTokenHeader)
      var requestCallsigns = axios.get(`https://${process.env.VUE_APP_API_URL}/user/callsigns${userId}`, this.$store.getters.authTokenHeader)
      var requestGroups = axios.get(`https://${process.env.VUE_APP_API_URL}/user/groups${userId}`, this.$store.getters.authTokenHeader)

      requestInfo.then(response => response.data)
        .then(json => {
          this.info = json;
        })
        .catch(err => {
          this.error = err.repose.data
        })

      requestCallsigns.then(response => response.data)
        .then(json => {
          this.callsigns = json

          for (var i = 0; i < this.callsigns.length; i++)
          {
            if (this.$dayjs(this.callsigns[i].startDate) > this.$dayjs())
              this.callsigns[i].warning = 'future'
            else if (this.$dayjs(this.callsigns[i].endDate) < this.$dayjs())
              this.callsigns[i].warning = 'expired'
            else
              this.callsigns[i].warning = null
          }
        })

      requestGroups.then(response => response.data)
        .then(json => this.groups = json)
    },
    save () {
      if (this.$refs.form.validate()) {
        this.saved = false;
        this.saving = true;

        // Native form submission is not yet supported
        const formData = {
          fullname: this.info.fullname,
          displayName: this.info.displayName,
          qth: this.info.qth,
          emailAddress: this.info.emailAddress,
          awardOptIn: this.info.awardOptIn ? 1 : 0,
          phoneOptIn: this.info.phoneOptIn ? 1 : 0,
          cwOptIn: this.info.cwOptIn ? 1 : 0,
          digitalOptIn: this.info.digitalOptIn ? 1 : 0,
          bandOptIn: this.info.bandOptIn ? 1 : 0
        }

        var userId = ''
        if (this.userId != null && this.isUserSupport)
          userId = `?userId=${this.userId}`

        axios
          .post(`https://${process.env.VUE_APP_API_URL}/user/info${userId}`, formData, this.$store.getters.authTokenHeader)
          .then(() => {
            this.saved = true;
            this.saving = false;
          })
      }
    },
    remove_callsign(callsign) {
      this.$dialog.confirm({ text: `Remove <b>${callsign}</b> from your callsigns?<br><br><small>NOTE: <b>Do not remove expired callsigns</b> if you want to retain credit for activations and QSOs from the period that they were active.</small>` }).then(res => {
        if (!res) return;

        const formData = { callsign: callsign }

        var userId = ''
        if (this.userId != null && this.isUserSupport)
          userId = `?userId=${this.userId}`

        axios
          .post(`https://${process.env.VUE_APP_API_URL}/user/callsigns/remove${userId}`, formData, this.$store.getters.authTokenHeader)
          .then(() => {
            location.reload();
          })
      })
    },
    add_callsign() {
      this.callsignEdit = false
      this.callsign = null
      this.startDate = null
      this.endDate = null
      this.callsignDialog = true
    },
    edit_callsign(callsign) {
      var update = null
      for (var c = 0; c < this.callsigns.length; c++) {
        if (this.callsigns[c].callsign == callsign)
        {
          update = this.callsigns[c]
          break
        }
      }

      if (!update)
        return

      this.callsignEdit = true
      this.callsign = update.callsign
      this.startDate = update.startDate
      this.endDate = update.endDate
      this.callsignDialog = true
    },
    save_callsign() {
      const formData = {
        callsign: this.callsign,
        startDate: this.startDate,
        endDate: this.endDate
      }

      var url = `https://${process.env.VUE_APP_API_URL}/user/callsigns/`
      if (this.callsignEdit)
        url += 'edit'
      else
        url += 'add'

      if (this.userId != null && this.isUserSupport)
        url += `?userId=${this.userId}`

      axios
        .post(url, formData, this.$store.getters.authTokenHeader)
        .then(() => {
          location.reload();
        })
        .catch(err => {
          this.$dialog.error({ text: `${err.response.data}` })
        })
    },
    active_callsign(callsign) {
      this.$dialog.confirm({ text: `Set <b>${callsign}</b> as your primary callsign?` }).then(res => {
        if (!res) return;

        const formData = { callsign: callsign }

        var userId = ''
        if (this.userId != null && this.isUserSupport)
          userId = `?userId=${this.userId}`

        axios
          .post(`https://${process.env.VUE_APP_API_URL}/user/callsigns/set${userId}`, formData, this.$store.getters.authTokenHeader)
          .then(() => {
            location.reload();
          })
      })
    },
    open_assign_group() {
      if (this.allGroups == null)
      {
        axios
          .get(`https://${process.env.VUE_APP_API_URL}/user/groups/list`, this.$store.getters.authTokenHeader)
          .then(response => response.data)
          .then(json => this.allGroups = json)
      }

      this.groupsDialog = true
    },
    assign_group(group) {
      this.$dialog.confirm({ text: `Assign <b>${group}</b> group to user permissions?<br><br><small>NOTE: This change will not take affect until the next time the user signs in.</small>` }).then(res => {
        if (!res) return;

        const formData = { group: group }

        var userId = ''
        if (this.info.userId != null && this.isUserSupport)
          userId = `?userId=${this.info.userId}`

        axios
          .post(`https://${process.env.VUE_APP_API_URL}/user/groups/assign${userId}`, formData, this.$store.getters.authTokenHeader)
          .then(() => {
            location.reload();
          })
      })
    },
    revoke_group(group) {
      this.$dialog.confirm({ text: `Revoke <b>${group}</b> group from user permissions?<br><br><small>NOTE: This change will not take affect until the next time the user signs in.</small>` }).then(res => {
        if (!res) return;

        const formData = { group: group }

        var userId = ''
        if (this.info.userId != null && this.isUserSupport)
          userId = `?userId=${this.info.userId}`

        axios
          .post(`https://${process.env.VUE_APP_API_URL}/user/groups/revoke${userId}`, formData, this.$store.getters.authTokenHeader)
          .then(() => {
            location.reload();
          })
      })
    },
    toggle_active() {
      this.changing_active = true;
      var status = this.info.active == 1 ? 0 : 1;

      const formData = { active: status };

      axios
          .post(`https://${process.env.VUE_APP_API_URL}/user/active?userId=${this.info.userId}`, formData, this.$store.getters.authTokenHeader)
          .then(() => {
            this.info.active = status;
            this.changing_active = false;
          })
    }
  },
  data () {
    return {
      needLoad: true,
      error: null,
      valid: true,
      info: null,
      callsigns: null,
      saving: false,
      saved: false,
      callsignDialog: false,
      callsignEdit: false,
      callsign: null,
      startDate: null,
      menuStartDate: false,
      endDate: null,
      menuEndDate: false,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'Example: user@domain.com', // http://emailregex.com/
        v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Example: user@domain.com'
      ],
      callsignRules: [
        v => !!v || 'Callsign is required',
        v => this.$store.state.global.validCallsignRegex.test(v) || 'Example: N0CALL'
      ],
      startDateRules: [
        v => !!v || 'Start Date is required',
        v => /^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/.test(v) || 'Format: YYYY/MM/DD'
      ],
      groupsDialog: false,
      allGroups: null,
      groups: [],

      changing_active: false
    }
  },
  props: [ 'userId' ],
  computed: {
    isAuthenticated: {
      get () {
        return this.$store.state.user.isAuthenticated
      }
    },
    isUserSupport: {
      get () {
        return this.$store.state.user.isUserSupport
      }
    },
    isUserManager: {
      get () {
        return this.$store.state.user.isUserManager
      }
    },
    gravatar: {
      get: function() {
        if (!this.info || !this.info.emailAddress)
          return '00000000000000000000000000000000'
        return md5(this.info.emailAddress.trim().toLowerCase())
      }
    }
  },
  components: {
  }
}
</script>
